<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <LoadingSpinner v-if="pageLoading"/>
    <div v-else class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img v-if="network_image" :src="network_image" alt="Cable Logo" />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-warning" v-if="pending_flag">
              <span>{{ pending_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Service Providers</label>
                <select
                  name="service_providers"
                  v-model="service_providers"
                  @change="handleCableChange"
                  class="form-control"
                  required
                >
                  <option value="">Select Providers</option>
                  <!-- {% for package in packages %} -->
                  <option
                    v-for="pack in packages"
                    :value="pack"
                    :key="pack.key"
                  >
                    {{ pack.title }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>
              <LoadingPlan v-if="loadingPlan"/>
              <div
                v-if="
                  service_providers.title &&
                  service_providers.title != 'SHOWMAX'
                "
                class="form-group"
              >
                <label>Smart Card No/IUC</label>
                <input
                  type="text"
                  name="smart_no"
                  v-model="smart_no"
                  required
                  class="form-control"
                />
                <p class="text-danger">
                  You can not proceed further until you validate your smart card
                  number. Please click the verify button below.
                </p>
                <br />

                <button
                  type="button"
                  @click="handleSmartNoChange"
                  class="btn btn-primary"
                  :disabled="verify"
                >
                  {{ verify ? "Verifying... Please wait" : "Verify" }}
                </button>

                <div v-if="customer_show" class="show_det">
                  <p v-if="customer_details.Customer_Name">
                    <strong>Customer Name:</strong>
                    {{ customer_details.Customer_Name }}
                  </p>
                </div>
              </div>
              <div
                v-if="validated || service_providers.title == 'SHOWMAX'"
                class="form-group"
              >
                <label>Cable Plans</label>
                <select
                  name="cable_plan"
                  v-model="cable_plan"
                  class="form-control"
                  @change="getDiscount"
                  required
                >
                  <option>Select Plan</option>
                  <option
                    v-for="plan in plans.plan"
                    :value="plan"
                    :key="plan.variation_code"
                  >
                    {{ plan.name }}
                  </option>
                </select>
              </div>
              <div v-if="validated" class="form-group">
                <label> Amount</label>
                <input
                  name="amt"
                  type="number"
                  id="amt"
                  readonly
                  v-model="cable_plan.amount"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>Phone </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  v-model="phone"
                  @keyup="validateInput"
                  class="form-control"
                />
                <span class="text-danger" v-if="!isValidPhoneNumber">Phone number is invalid</span>
              </div>
              <div
                v-if="amount_charged_text"
                style="margin-bottom: 10px; color: #0a8aaf"
              >
                {{ amount_charged_text }}
              </div>
              <div
                class="form-group"
              >
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="isLoading"
                >
                  {{ isLoading ? "Processing..." : "Process" }}
                </button>
              </div>

              <router-link to="/dashboard/transactions" class="nav-link"
                ><i class="fas fa-fw fa-th"></i>
                <span>View Transactions</span></router-link
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---Container Fluid-->
</template>
    
<script>
export default {
  name: "Cable",
  data() {
    return {
      service_providers: {},
      cable_plan: {},
      amt: "",
      phone: "",
      smart_no: "",
      customer_details: {},
      discount: 0,
      amount_charged_text: "",
      network_image: "",
      errors: [],
      success_flag: "",
      pending_flag: "",
      packages: {},
      plans: {},
      isLoading: false,
      isLoading2: false,
      validated: false,
      customer_show: false,
      pageLoading: false,
      verify: false,
      loadingPlan: false,
      isValidPhoneNumber: true,
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    validateInput(event) {
      const validationRegex = /^[+]?[0-9]*$/;
      if (this.phone.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    getPackages() {
      this.pageLoading = true;
      this.$axios
        .get("get-tv-packages/")
        .then((response) => {
          // console.log(response.data.data);
          this.packages = response.data.data;
          this.pageLoading = false;
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
            this.errors.push(error.response.data.message);

            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
          this.pageLoading = false;
        });
    },

    validate(e) {
      const subcategory_id = this.service_providers.id;
      const type = this.service_providers.title;
      const smart_no = this.smart_no;

      this.customer_details = {};
      this.success_flag = "";
      this.pending_flag = "";
      this.errors = [];
      this.verify = true;

      if (smart_no == "") {
        this.errors.push("Smart/IUC number required");
        this.notify.error("Smart/IUC number required");
      } else {
        this.isLoading2 = true;
        this.amt = "";

        this.errors = [];

        this.$axios
          .get(
            `validate-tv/${subcategory_id}/${type}/${smart_no}/`
          )
          .then((response) => {
            // console.log(response);
            // console.log(response.data.data.data.content);
            var my_error = response.data.data.content.error;
            this.verify = false;
            if (my_error == undefined) {
              this.customer_details = response.data.data.content;
              this.customer_show = true;
            } else {
              this.errors.push(my_error);
            }

            this.isLoading2 = false;
            this.validated = true;

            // if (type != "STARTIMES") {
            //   this.validated = true;
            // } else {
            //   this.validated = false;
            // }

            //   this.packages = response.data.data;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.verify = false;
          });
      }
    },

    handleCableChange() {
      this.loadingPlan = true;
      this.errors = [];
      this.amt = "";
      this.amount_charged_text = "";
      this.customer_details = "";

      this.discount = this.service_providers.discount;
      // this.network_image =
      //   process.env.VUE_APP_IMAGE_URL + this.service_providers.image;

      if (this.service_providers != "") {
        this.$axios
          .get(`get-tv-plan/${this.service_providers.id}/`)
          .then((response) => {
            // console.log(response);
            // console.log(response.data.data.data.content.varations);
            this.network_image = process.env.VUE_APP_IMAGE_URL + response.data.data[0].image;
            this.plans = response.data.data[0];
            
            this.loadingPlan = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      } else {
        this.network_image = "";
      }
      //   console.log(this.amount_charged_text);
    },

    handleSmartNoChange() {
      this.customer_details = {};
      this.validate();
    },

    getDiscount() {
      const amount_charged = this.cable_plan.discount.toFixed(2);

      this.amount_charged_text =
        "You will be charged ₦" + amount_charged.toString();

      // console.log(this.amount_charged_text);
    },

    submitForm(e) {
      this.errors = [];
      this.amt =
        this.cable_plan.amount != ""
          ? this.cable_plan.amount
          : this.customer_details.Renewal_Amount;

      this.success_flag = "";
      this.pending_flag = "";

      // console.log(this.amt);

      if (this.phone == "") {
        this.errors.push("Phone number is required!");
        this.notify.error("Phone number is required!");
      } else if (this.amt == "") {
        this.errors.push("Amount is required!");
        this.notify.error("Phone number is required!");
      } else if (this.amt < 100) {
        this.errors.push("Amount can not be less than ₦100");
        this.notify.error("Amount can not be less than ₦100");
      } else if (this.service_providers == []) {
        this.errors.push("Select a provider from the list");
        this.notify.error("Select a provider from the list");
      } else {
        const formData = {
          phone: this.phone,
          variation_code: this.cable_plan.variation_code,
          smart_no: this.smart_no,
          bouquet_name: this.cable_plan.name,
          subcategory_id: this.service_providers.id,
        };
        // console.log(formData);
        
        this.isLoading = true;
        this.$axios
          .post("purchase-cable/", formData)
          .then((response) => {
            // console.log(response);
            if (response.status == 200) {
              if (this.service_providers.title == "SHOWMAX") {
                this.success_flag =
                  response.data.message +
                  ". Please check your transaction history for your VOUCHER CODE.";
                this.notify.success(
                  response.data.message +
                    ". Please check your transaction history for your VOUCHER CODE."
                );
              } else {
                this.success_flag = response.data.message;
                this.notify.success(response.data.message);
              }
            } else if (response.status == 201) {
              this.pending_flag = response.data.message;
              this.notify.pending(response.data.message);
            }

            this.isLoading = false;
            this.smart_no = "";
            this.customer_details = {};
            this.cable_plan = {};
            this.phone = "";
            this.amount_charged_text = "";
            this.service_providers = {};
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
    
    <style scoped>
.network-logo {
  text-align: center;
  margin-bottom: 20px;
}
.network-logo img {
  width: 100px;
}
.network-error {
  color: red;
}
.notification {
  margin: 10px 0 10px;
}
.card-body {
  padding: 30px;
}
label {
  font-weight: bold;
}
input {
  margin-bottom: 20px;
}
.row {
  margin-bottom: 50px;
}
.show_det {
  background-color: #cdcdcd;
  padding: 10px;
  border-radius: 5px;
  border-color: grey;
  margin: 10px 0 10px;
}
#amt {
  pointer-events: none;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}

.nav-link {
  color: #0a8aaf;
}

</style>