<template>
    <div class="loading-spinner">
        <div class="spinner"></div>
        <p>Loading Plans... Please wait</p>
    </div>
</template>
<script>
export default {
    name: "LoadingPlan",
}
</script>
<style scoped>
.loading-spinner {
    display:flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    margin: 20px 0 20px 0;
    background: transparent;
}
.spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-left-color: #0a8aaf;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
</style>