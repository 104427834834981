<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path:'/dashboard' }">{{ this.$route.meta.home_title }} </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <LoadingSpinner v-if="pageLoading"/>
    <div v-else class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img
                v-if="network_image"
                :src="network_image"
                alt="Exam Pins Logo"
              />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-warning" v-if="pending_flag">
              <span>{{ pending_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Exam Type</label>
                <select
                  name="service_providers"
                  v-model="service_providers"
                  @change="handleExamChange"
                  class="form-control"
                  required
                >
                  <option value="">Select Exam </option>
                  <!-- {% for package in packages %} -->
                  <option v-for="pack in packages" :value="pack" :key="pack.key">
                    {{ pack.title }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>
              <LoadingPlan v-if="loadingPlan" />
              <div v-else class="form-group">
                <label>Select Type</label>
                <select
                  name="exam_type"
                  v-model="exam_type"
                  class="form-control"
                  @change="getDiscount"
                  required
                >
                  <option>Select Exam Type</option>
                  <option
                    v-for="plan in plans.plan"
                    :value="plan"
                    :key="plan.variation_code"
                  >
                    {{ plan.name }}
                  </option>
                </select>
              </div>

              <div v-if="service_providers.title=='JAMB'" class="form-group">
                <label>Profile ID</label>
                <input
                  type="text"
                  name="profile_id"
                  v-model="profile_id"
                  @keydown="validateInput"
                  required
                  class="form-control"
                />
                <br/>
                <button
                  type="button"
                  @click="handleProfileID"
                  class="btn btn-primary"
                  :disabled="verify"
                >
                 {{ verify ? "Verifying... Please wait" : "Verify" }}
                </button>               

                <div v-if="customer_show" class="show_det">
                  <p v-if="customer_details.Customer_Name">
                    <strong>Customer Name:</strong>
                    {{ customer_details.Customer_Name }}
                  </p>
                </div>
                
              </div>

              <div v-if="exam_type.name != null" class="form-group">
                <label> Amount</label>
                <input
                  name="amt"
                  type="number"
                  id="amt"
                  readonly
                  v-model="exam_type.amount"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>Phone </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  v-model="phone"
                  @keyup="validateInput"
                  class="form-control"
                />
                <span class="text-danger" v-if="!isValidPhoneNumber">Phone number is invalid</span>
              </div>
              <div
                v-if="amount_charged_text"
                style="margin-bottom: 10px; color:#0a8aaf;"
              >
                {{ amount_charged_text }}
              </div>
              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-primary"
                  
                  :disabled="isLoading"
                >
                 {{ isLoading ? "Processing..." : "Process" }}
                </button>

              </div>
              
              <router-link to="/dashboard/transactions" class="nav-link"
        ><i class="fas fa-fw fa-th"></i> <span>View Transactions</span></router-link
      >
              
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Exam",

  data() {
    return {
      service_providers: {},
      amt: "",
      phone: "",
      exam_type: {},
      profile_id: "",
      discount: 0,
      amount_charged_text: "",
      network_image: "",
      errors: [],
      success_flag: "",
      pending_flag: "",
      packages: {},
      plans: {},
      isLoading: false,
      validated: false,
      verify: false,
      customer_show: false,
      pageLoading: false,
      loadingPlan: false,
      isValidPhoneNumber: true,
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    validateInput(event) {
      const validationRegex = /^[+]?[0-9]*$/;
      if (this.phone.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },

    getPackages() {
      this.pageLoading = true
      this.errors = [];
      this.$axios
        .get("get-exam-packages/")
        .then((response) => {
          // console.log(response.data.data);
          this.packages = response.data.data;
          this.pageLoading = false
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message)

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          this.pageLoading = false
        });
    },

    handleExamChange() {
      this.loadingPlan = true;
        this.errors = [];
        this.exam_type.amount = "";
        this.amount_charged_text = "";
        this.success_flag = "";     

          if (this.service_providers != "") {
        this.$axios
          .get(`get-exam-plan/${this.service_providers.id}/`)
          .then((response) => {
            // console.log(response);
            this.plans = response.data.data[0];
            this.discount = response.data.data[0].amount;
            this.network_image =
              process.env.VUE_APP_IMAGE_URL + response.data.data[0].image;
            // this.validated = true;
            this.loadingPlan = false;
          })
          .catch((error) => {
            // this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message)

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      } else {
        this.network_image = "";
      }

          // this.network_image = "";
          
        //   console.log(this.amount_charged_text);
      },

      handleProfileID(){
          this.customer_details = {};
          this.validate();
      },

      validate(e) {
      const subcategory_id = this.service_providers.id;
      const service_id = this.exam_type.service_id;
      const profile_id = this.profile_id;
      const variation_code = this.exam_type.variation_code

      this.customer_details = {};
      this.success_flag ="";
      this.pending_flag ="";
      this.errors =[];
      this.verify = true;

      if (profile_id == "" && this.service_providers.title == "JAMB") {
        this.errors.push("Profile ID is required");
      } else {
        this.isLoading2 = true;
        this.amt = "";

        this.errors = [];

        this.$axios
          .get(
            `validate-exam/${subcategory_id}/${service_id}/${profile_id}/${variation_code}/`
          )
          .then((response) => {
            this.verify = false;
            // console.log(response.data.data.data.content);
            var my_error = response.data.data.content.error;
            if (my_error == undefined) {
              this.customer_details = response.data.data.content;
              this.customer_show = true;
              this.validated = true;
            } else {
              this.errors.push(my_error);
              this.validated = false;
            }

            this.isLoading2 = false;

            
             
            //   this.packages = response.data.data;
          })
          .catch((error) => {
              this.isLoading = false;
              // console.log(error.response);
              if (error.response) {
              this.errors.push(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.verify = false;
            });
      }
    },
    getDiscount() {
       
        var amount_charged = this.exam_type.discount.toFixed(2);
  
        this.amount_charged_text =
          "You will be charged ₦" + amount_charged.toString();
  
        // console.log(this.amount_charged_text);
      },

      submitForm(e) {
        this.errors = [];
        this.success_flag ="";
        this.pending_flag ="";
  
        if (this.phone == "") {
          this.errors.push("Phone number is required!");
          this.notify.error("Phone number is required!");
        } else if (this.exam_type.variation_amount == "") {
          this.errors.push("Amount is required!");
          this.notify.error("Amount is required!");
        }else if (this.service_providers == []) {
        this.errors.push("Select a provider from the list");
        this.notify.error("Select a provider from the list");
      } else {
          const service_id =  this.exam_type.service_id;
          // console.log(service_id)

          const formData = {
            subcategory_id: this.service_providers.id,
            phone: this.phone,
            billersCode: this.profile_id,
            variation_code: this.exam_type.variation_code,
            service_id: service_id,
          };
          // console.log(formData);
          // "size": size,
          //     "title": plan_name,
          //     "subcategory_id": subcategory_id,
  
          this.isLoading = true;
          this.$axios
            .post("purchase-exam-pins/", formData)
            .then((response) => {
              // console.log(response);
              if(response.status == 200){
                this.success_flag = response.data.message + ". Please check your transaction history for your Pin Details.";
                this.notify.success(response.data.message + ". Please check your transaction history for your Pin Details.");
            }else if(response.status == 201){
              this.pending_flag =
              response.data.message;
              this.notify.pending(response.data.message);
            }

              
              this.isLoading = false;
              this.meter_no = "";
              this.meter_type = "";
              this.customer_details= {};
              this.amt = "";
              this.phone = "";
              this.amount_charged_text="";
              this.service_providers = {};
            })
            .catch((error) => {
              this.isLoading = false;
              // console.log(error.response);
              if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            });
        }
      },



  },
};
</script>
<style scoped>
.network-logo {
  text-align: center;
  margin-bottom: 20px;
}
.network-logo img {
  width: 100px;
}
.network-error {
  color: red;
}
.notification {
  margin: 10px 0 10px;
}
.card-body {
  padding: 30px;
}
label {
  font-weight: bold;
}
input {
  margin-bottom: 20px;
}
.row {
  margin-bottom: 50px;
}
.show_det {
  background-color: #cdcdcd;
  padding: 10px;
  border-radius: 5px;
  border-color: grey;
  margin: 10px 0 10px;
}
#amt{
  pointer-events: none;
}
a:link {
  text-decoration: none;
}
.btn-primary{
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}

.nav-link {
  color: #0a8aaf;
}

</style>