<template>
  <div>
    <section class="page-header">
           
    </section>
    <section class="contact-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="contact-page__center">
              <div class="section-title text-center">
                <div class="section-sub-title-box">
                  <h2 class="contact-title">Contact us</h2>
                  <div class="section-title-shape-1">
                    <img
                      src="@/assets/frontend/images/shapes/section-title-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div class="section-title-shape-2">
                    <img
                      src="@/assets/frontend/images/shapes/section-title-shape-2.png"
                      alt=""
                    />
                  </div> 
                </div>
                <h3 class="section-title__title">
                  Feel free to get in touch with us
                </h3>

                <div class="contact_page">
                    <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="contact-box text-center">
                            <i class="fa fa-map-marker"></i>
                            <h3 class="mt-20">Address</h3>
                            <p class="mt-20">{{ pages.address }} </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="contact-box text-center">
                            <i class="fa fa-phone"></i>
                            <h3 class="mt-20">Phone</h3>
                            <p class="mt-20">{{ pages.phone }}</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="contact-box text-center">
                            <i class="fa fa-envelope"></i>
                            <h3 class="mt-20">Email</h3>
                            <p class="mt-20">{{ pages.email }}</p>
                        </div>
                    </div>

                </div>
                </div>
                <div class="contact_page"><div class="row mt-20">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="contact-box text-center">
                            <h3 class="mt-20">Twitter</h3>
    
                            <a :href="pages.twitter"><i class="fab fa-twitter"></i></a>
    
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="contact-box text-center">
                            <h3 class="mt-20">Facebook</h3>
                            <a :href="pages.facebook"><i class="fab fa-facebook"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="contact-box text-center">
                            <h3 class="mt-20">Instagram</h3>
                            <a :href="pages.instagram"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div></div>
                
           
                

              </div>
             
            </div>
          </div>
          
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props:['pages'],
  name: "ContactView",
};
</script>

<style scoped>
.contact_page{
   margin-top: 50px;
}
.fa-facebook,.fa-twitter,.fa-instagram, .fa-map-marker, .fa-phone, .fa-envelope {
    margin-top: 20px;
    font-size: 25px;
}
.contact-title {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 50px;

}
.section-title__title {
  font-size: 25px
}
</style>