<template>
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Transactions</li>
      </ol>
    </div>
    <LoadingSpinner v-if="pageLoading" />
    <div v-else class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <!-- Simple Tables -->

          <div class="card shadow mb-6">
            <div class="card-body">
              <div class="table-responsive">
                <table
                  class="table table-bordered table-hover"
                  ref="myTransactions"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Plan</th>
                      <th>Phone</th>
                      <th>IUC/Meter</th>
                      <th>Description</th>
                      <th>Response</th>
                      <th>Amount</th>
                      <th>Token</th>
                      <th>Prev. Bal</th>
                      <th>Current Bal</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>ID</th>
                      <th>Plan</th>
                      <th>Phone</th>
                      <th>IUC/Meter</th>
                      <th>Description</th>
                      <th>Response</th>
                      <th>Amount</th>
                      <th>Token</th>
                      <th>Prev. Bal</th>
                      <th>Current Bal</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr
                      v-for="transaction in transactions"
                      :key="transaction.id"
                    >
                      <td>{{ transaction.transaction_ref }}</td>
                      <td>{{ transaction.plan }}</td>
                      <td>{{ transaction.phone_number }}</td>
                      <td>{{ transaction.smart_no || transaction.meter_no}}</td>
                      <td>
                        <div v-if="transaction.description == null">
                          {{ transaction.description }}
                        </div>
                        <div v-else-if="transaction.description.length < 40">
                          {{ transaction.description }}
                        </div>
                        <div v-else>
                          <div v-if="showMoreDes == false">
                            {{
                              transaction.description.substring(0, 40) + "..."
                            }}
                          </div>
                          <div v-else>
                            {{ transaction.description }}
                          </div>
                          <button
                            @click="showMoreDes = !showMoreDes"
                            class="btn btn-success"
                          >
                            <i
                              v-if="showMoreDes == false"
                              class="fas fa-eye"
                            ></i>
                            <i v-else class="fas fa-eye-slash"></i>
                          </button>
                        </div>
                        <div v-if="transaction.description2 == null">
                          {{ transaction.description2 }}
                        </div>
                        <div v-else-if="transaction.description2.length < 40">
                          {{ transaction.description2 }}
                        </div>
                        <div v-else>
                          <div v-if="showMoreDesc == false">
                            {{
                              transaction.description2.substring(0, 40) + "..."
                            }}
                          </div>
                          <div v-else>
                            {{ transaction.description2 }}
                          </div>
                          <button
                            @click="showMoreDesc = !showMoreDes"
                            class="btn btn-success"
                          >
                            <i
                              v-if="showMoreDesc == false"
                              class="fas fa-eye"
                            ></i>
                            <i v-else class="fas fa-eye-slash"></i>
                          </button>
                        </div>
                      </td>
                      <td>
                        <div v-if="transaction.response == null">
                          {{ transaction.response }}
                        </div>
                        <div v-else-if="transaction.response.length < 40">
                          {{ transaction.response }}
                        </div>
                        <div v-else>
                          <div v-if="showMoreResp == false">
                            {{ transaction.response.substring(0, 40) + ".." }}
                          </div>
                          <div v-else>
                            {{ transaction.response }}
                          </div>
                          <button
                            @click="showMoreResp = !showMoreResp"
                            class="btn btn-success"
                          >
                            <i
                              v-if="showMoreResp == false"
                              class="fas fa-eye"
                            ></i>
                            <i v-else class="fas fa-eye-slash"></i>
                          </button>
                        </div>
                      </td>
                      <td>&#8358;{{ $filters.formatNumber(transaction.subtotal) }}</td>
                      <td>{{ transaction.exam_card || transaction.token }}</td>

                      <td>
                        &#8358;{{ $filters.formatNumber(transaction.initial_balance) }}
                      </td>
                      <td>
                        &#8358;{{ $filters.formatNumber(transaction.new_balance) }}
                      </td>
                      <td v-if="transaction.status == 0">
                        <span class="badge badge-warning"> Pending </span>
                      </td>
                      <td v-else-if="transaction.status == 1">
                        <span class="badge badge-success"> Confirmed </span>
                      </td>
                      <td v-else-if="transaction.status == 2">
                        <span class="badge badge-danger"> Failed </span>
                      </td>
                      <td v-else-if="transaction.status == 3">
                        <span class="badge badge-danger"> Ignored </span>
                      </td>
                      <td v-else-if="transaction.status == 4">
                        <span class="badge badge-info"> Reversed </span>
                      </td>
                      <td>{{ $filters.formatDateTime(transaction.created_at) }}</td>
                      <td>
                        <router-link
                          v-if="transaction.status == '1'"
                          :to="{
                            name: 'receipt',
                            params: { trans_id: transaction.id},
                          }"
                          class="btn btn-primary"
                          >Receipt
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
      <!--Row-->

      <!---Container Fluid-->
    </div>
  </div>
</template>
<script>
export default {
  name: "Transactions",
  data() {
    return {
      transactions: [],
      showMoreDes: false,
      showMoreDesc: false,
      showMoreResp: false,
      pageLoading: false,
    };
  },

  mounted() {
    this.dt = $(this.$refs.myTransactions).DataTable({
      order: [[11, "desc"]], //or asc
      columnDefs: [{ targets: 11, type: "date" }],
    });
    // setTimeout(() => this.transactions = fakeData(50), 1000);
    this.getTransactions();
  },
  watch: {
    transactions(val) {
      this.dt.destroy();
      this.$nextTick(() => {
        this.dt = $(this.$refs.myTransactions).DataTable({
          order: [[11, "desc"]], //or asc
          columnDefs: [{ targets: 11, type: "date" }],
        });
      });
    },
  },

  methods: {
    getTransactions() {
      this.pageLoading = true
      this.$axios
        .get("get-transactions/")
        .then((response) => {
          //   console.log(response.data.data);
          this.transactions = response.data.data;
          this.pageLoading = false
          // this.profile = response.data.data.profile
          // this.formatDate = moment(response.data.data.date_joined).format("Do MMM, YYYY")
          // this.user_level = getUserLevel(response.data.data.profile.user_level)
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
                  this.pageLoading = false

        });
    },

    
   
  },
};
</script>
<style scoped>
a:link {
  text-decoration: none;
}
.btn-primary{
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}
.btn-success{
  background-color: #0a8aaf;
  border-color:#0a8aaf;
}

</style>