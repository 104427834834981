<template>
  <LoadingSpinner v-if="pageLoading"/>
  <div v-else class="col-xl-6 col-md-9 col-lg-6 mx-auto">
    <div class="card mb-3" id="printMe">
      <div
        class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
      >
        <div class="col-md-6 col-lg-6 col-xl-9 mx-auto">
          <table class="table">
            <tr>
              <td colspan="2" class="receipt_logo">
                <img src="@/assets/backend/img/logo/logo.png" />
              </td>
            </tr>
            <tr>
              <td colspan="2" class="receipt_head">Topupwaveng Receipt</td>
            </tr>
            <tr>
              <th>Ref</th>
              <td>{{ transactions.transaction_ref }}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{{ transactions.plan }}</td>
            </tr>
            <tr v-if="transactions.smart_no != null">
              <th>Card No</th>
              <td>{{ transactions.smart_no }}</td>
            </tr>
            <tr v-if="transactions.meter_no != null">
              <th>Meter No</th>
              <td>{{ transactions.meter_no }}</td>
            </tr>
            <tr v-if="transactions.meter_no != null">
              <th>Token</th>
              <td>{{ transactions.token.split(":")[1] }}</td>
            </tr>
            <tr v-if="transactions.phone_number">
              <th>Phone</th>
              <td>{{ transactions.phone_number }}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>&#8358;{{ transactions.amount }}</td>
            </tr>
            <tr>
              <th>Status</th>

              <td>
                <label
                  class="badge badge-warning"
                  v-if="transactions.status == 0"
                  >Pending</label
                >

                <label
                  class="badge badge-success"
                  v-else-if="transactions.status == 1"
                  >Confirmed</label
                >

                <label
                  class="badge badge-danger"
                  v-else-if="transactions.status == 2"
                  >Failed</label
                >
                <label
                  class="badge badge-danger"
                  v-else-if="transactions.status == 3"
                  >Ignored</label
                >
                <label
                  class="badge badge-danger"
                  v-else-if="transactions.status == 4"
                  >Reversed</label
                >
              </td>
            </tr>
            <tr>
              <th>Date</th>
              <td>{{ $filters.formatDateTime(transactions.created_at) }}</td>
            </tr>
          </table>
          <div class="btn_receipt">
            <button class="btn btn-primary" @click="print">Print</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      transactions: [],
      pageLoading: false,
    };
  },

  mounted() {
    this.getTransactions();
  },
  methods: {
    print() {
      this.$htmlToPaper("printMe");
    },
    getTransactions() {
      this.pageLoading = true;
      this.$axios
        .get(`get-transactions/${this.$route.params.trans_id}`)
        .then((response) => {
          // console.log(response.data.data);
          this.transactions = response.data.data[0];
          this.pageLoading = false;
          // this.profile = response.data.data.profile
          // this.formatDate = moment(response.data.data.date_joined).format("Do MMM, YYYY")
          // this.user_level = getUserLevel(response.data.data.profile.user_level)
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
          this.pageLoading = false;
        });
    },
  },
};
</script>
  
  <style scoped>
img {
  width: 10vw !important;
  height: 10vw !important;
  padding: 10px;
}

input[type="radio"] {
  display: none;
}

img:hover {
  opacity: 0.6;
  cursor: pointer;
}

img:active {
  opacity: 0.4;
  cursor: pointer;
}

input[type="radio"]:checked + label > img {
  border: 2px solid #6777ef;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  border: 2px solid #ccc;
  margin: 5px;
  border-radius: 10px;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.receipt_head {
  font-weight: bold !important;
  font-size: 15px !important;
}
.receipt_logo {
  text-align: center !important;
}

.btn_receipt {
  margin-top: 10px;
  text-align: right !important;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}

.table {
  font-size: 14px !important;
  word-wrap: break-word !important;
  padding-left: 0;
}
.table > tr:first-child tr:last-child td:first-child td:last-child {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

.card-header {
  padding: 0;
}

.card .table td,
.card .table th {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media print {
  body * {
    visibility: hidden;
    margin: 0;
    color: #000 !important;
  }

  #printMe,
  #printMe * {
    visibility: visible;
    border: none;
  }
}
</style>