<template>
  <div>
    <section class="page-header">
     
    </section>
    <section class="sign-in">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-6 col-lg-9 col-md-10">
            <div class="sign-in__single">
              <h3 class="sign-in__title">Forgot Password</h3>
              <div class="col-lg-12">
                <div class="notification" v-if="errors.length">
                  <p
                    v-for="error in errors"
                    v-bind:key="error"
                    class="alert alert-danger"
                  >
                    {{ error }}
                  </p>
                </div>
                <div
                  class="notification alert alert-success"
                  v-if="success_flag"
                >
                  {{ success_flag }}
                </div>
              </div>
              <form class="sign-in__form" @submit.prevent="submitForm">
                <div class="sign-in__form-input-box">
                  <input
                    type="email"
                    placeholder="Email address*"
                    required
                    v-model="email"
                  />
                </div>

                <div class="sign-in__form-btn-box">
                  <button
                    type="submit"
                    :disabled="isLoading"
                    class="thm-btn sign-in__form-btn"
                  >
                    {{ isLoading ? "Processing..." : "Forgot Password"}}
                  </button>
                  
                  </div>
                  <div class="sign-in__form-btn-box">
                    <div class="sign-in__form-forgot-password bk-login">
                    <router-link to="/login" class="forgot"
                      ><strong>Back to Login</strong></router-link
                    >
                  </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {
  name: "ForgotPasswordView",
  data() {
    return {
      email: "",
      success_flag: "",
      errors: [],
      isLoading: false,
    };
  },
  methods: {
    submitForm(e) {
      this.errors = [];
      this.success_flag = "";

      if (this.email == "") {
        this.errors.push("Email is required!");
        this.notify.error("Email is required!");
      } else {
        const formData = {
          email: this.email,
        };

        console.log(formData)
        this.isLoading = true;
        this.$axios
          .post("password_reset/", formData)
          .then((response) => {
            // console.log(response)
            this.success_flag =
              "An Email has been sent to your email address, please follow the instructions to reset your password";
            this.notify.success("An Email has been sent to your email address, please follow the instructions to reset your password");
            this.isLoading = false;

          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data.errors) {
                this.errors.push(
                  `${error.response.data.errors[property]["detail"]}`
                );
                this.notify.error(`${error.response.data.errors[property]["detail"]}`);
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.isLoading = false;
          });
      }
      // Profile details
    },
  },
};
</script>
  
  <style scoped>
.fg {
  text-align: right;
}
label {
  font-weight: bold;
}
.bk-login {
  display: flex;
  margin: 10px 0 0 0;
  justify-content: center;
  width: 100%;
}
</style>