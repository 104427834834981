<template>
  <div>
    <section class="page-header">
          
    </section>

    <section class="faq-one">
      <div class="container">
        <div class="row">
           
          <div class="col-xl-12 col-lg-12">
            <div class="about-four__center">
              <div class="section-title text-center">
                <div class="section-sub-title-box">
                  <p class="section-sub-title">Frequently Asked Questions</p>
                  <div class="section-title-shape-1">
                    <img
                      src="@/assets/frontend/images/shapes/section-title-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div class="section-title-shape-2">
                    <img
                      src="@/assets/frontend/images/shapes/section-title-shape-2.png"
                      alt=""
                    />
                  </div>
                </div>
                
              </div>
              
              <p class="about-four__text-2" v-html="pages.faq">
               
              </p>
            
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  props: ['pages'],
  name: "FaqView",
};
</script>

<style>
</style>