<template>
  <div>
    <section class="page-header"></section>

    <section class="about-four">
      <div class="container">
        <div class="section-title text-center">
          <div class="section-sub-title-box">
            <p class="section-sub-title">About us</p>
            <div class="section-title-shape-1">
              <img
                src="@/assets/frontend/images/shapes/section-title-shape-1.png"
                alt=""
              />
            </div>
            <div class="section-title-shape-2">
              <img
                src="@/assets/frontend/images/shapes/section-title-shape-2.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <div class="about-four__left">
              <div
                class="about-four__img-box wow slideInLeft animated"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
                style="
                  visibility: visible;
                  animation-duration: 2500ms;
                  animation-delay: 100ms;
                  animation-name: slideInLeft;
                "
              >
                <div class="about-four__img">
                  <img src="@/assets/frontend/images/resources/logo3.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8" style="text-align: justify">
            <div class="about-four__right">
              <p class="about-four__text-2" v-html="pages.about_us"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="brand-one">
      <div class="container">
        <div class="row">
          <div class="col-xl-3">
            <div class="brand-one__title">
              <h2>Our partners</h2>
            </div>
          </div>
          <div class="col-xl-9">
            <div class="brand-one__main-content">
              <div
                class="thm-swiper__slider swiper-container swiper-container-initialized swiper-container-horizontal"
                data-swiper-options='{"spaceBetween": 100, "slidesPerView": 5, "autoplay": { "delay": 5000 }, "breakpoints": {
                     "0": {
                         "spaceBetween": 30,
                         "slidesPerView": 2
                     },
                     "375": {
                         "spaceBetween": 30,
                         "slidesPerView": 2
                     },
                     "575": {
                         "spaceBetween": 30,
                         "slidesPerView": 3
                     },
                     "767": {
                         "spaceBetween": 50, 
                         "slidesPerView": 4
                     },
                     "991": {
                         "spaceBetween": 50,
                         "slidesPerView": 5
                     },
                     "1199": {
                         "spaceBetween": 100,
                         "slidesPerView": 5
                     }
                 }}'
              >
                <div
                  class="swiper-wrapper"
                  style="
                    transform: translate3d(-970px, 0px, 0px);
                    transition-duration: 0ms;
                  "
                >
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/mtn.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/glo.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/airtel.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/9mobile.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide swiper-slide-prev"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/dstv.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide swiper-slide-active"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/gotv.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide swiper-slide-next"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/startimes.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/waec.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/jamb.png" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/ibedc.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/ikeja.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/eko.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/jos.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/kano.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/kaduna.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                  <!-- /.swiper-slide -->
                  <div
                    class="swiper-slide"
                    style="width: 94px; margin-right: 100px"
                  >
                    <img src="@/assets/frontend/images/vtu/portharcourt.jpg" alt="" />
                  </div>
                  <!-- /.swiper-slide -->
                </div>
                <span
                  class="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ["pages"],
  name: "AboutView",
};
</script>

<style scoped>
.about-four__text-2 {
  margin: 0 20px;
}
</style>