<template>
    <div class="loading-spinner">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="shimmer"></div>
    </div>
</template>
<script>
export default {
    name: "LoadingSpinner",
}
</script>
<style scoped>
.loading-spinner {
    background: #ddd;
    width: 100%;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}

.loading-spinner .line{
    width: 100%;
    height: 20px;
    background: #bbb;
    margin: 20px 0;
    border-radius: 5px;
}

.loading-spinner .shimmer{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
 
    background: linear-gradient(100deg,
    rgba(255,255,255,0) 20%,
    rgba(255,255,255,0.5) 50%,
    rgba(255,255,255,0) 80%);
 
    animation: shimmer 2s infinite linear;
}


@keyframes shimmer{
    from {
        transform: translateX(-200%);
    }
    to{
        transform: translateX(200%);
    }
}
</style>