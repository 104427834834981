<template>
  <div>
    <section class="page-header">
    
    </section>
    <section class="pricing">
      <div class="container">
        <div class="section-title text-center">
          <div class="section-sub-title-box">
            <h2 class="pricing-title">Pricing plans</h2>
            <div class="section-title-shape-1">
              <img
                src="@/assets/frontend/images/shapes/section-title-shape-1.png"
                alt=""
              />
            </div>
            <div class="section-title-shape-2">
              <img
                src="@/assets/frontend/images/shapes/section-title-shape-2.png"
                alt=""
              />
            </div>
          </div>
          <h3 class="section-title__title">
            Choose the best plans <br />
            for yourself
          </h3>
        </div>
        <LoadingSpinner v-if="isLoading"/>

        <div v-else class="pricing__tab">
          <div class="pricing__tab-box tabs-box">
            <div class="tabs-content">
              <!--tab-->
              <div class="tab active-tab" id="monthly">
                <div class="pricing__main-content-box">
                  <div class="row">
                    <!--Pricing Single Start-->
                    <div
                      class="col-xl-6 col-lg-6"
                      v-for="list in lists"
                      :key="list.key"
                    >
                      <div class="pricing__single">
                        <div class="pricing__single-bottom">
                          <h3
                            class="pricing__title"
                            v-if="list.category_id == 1"
                          >
                            <img
                              width="40px"
                              height="40px"
                              class="title_image"
                              :src="makeImage(list.image)"
                            />
                            {{ list.title }} Data
                          </h3>
                          <h3
                            class="pricing__title"
                            v-else-if="list.category_id == 2"
                          >
                            <img
                              width="40px"
                              height="40px"
                              class="title_image"
                              :src="makeImage(list.image)"
                            />
                            {{ list.title }} Airtime
                          </h3>
                          <h3
                            class="pricing__title"
                            v-else-if="list.category_id == 3"
                          >
                            <img
                              width="40px"
                              height="40px"
                              class="title_image"
                              :src="makeImage(list.image)"
                            />
                            {{ list.title }}
                          </h3>
                          <h3
                            class="pricing__title"
                            v-else-if="list.category_id == 4"
                          >
                            <img
                              width="40px"
                              height="40px"
                              class="title_image"
                              :src="makeImage(list.image)"
                            />
                            {{ list.title }}
                          </h3>
                          <h3
                            class="pricing__title"
                            v-else-if="list.category_id == 6"
                          >
                            <img
                              width="40px"
                              height="40px"
                              class="title_image"
                              :src="makeImage(list.image)"
                            />
                            {{ list.title }}
                          </h3>
                          <h3
                            class="pricing__title"
                            v-else-if="list.category_id == 11"
                          >
                            <img
                              width="40px"
                              height="40px"
                              class="title_image"
                              :src="makeImage(list.image)"
                            />
                            {{ list.title }}
                          </h3>
                          <h3 class="pricing__title" v-else>
                            {{ list.title }}
                          </h3>
                          <table class="table table-bordered">
                            <tr>
                              <th><b>Plan</b></th>
                              <th><b>Normal</b></th>
                              <th><b>Agent</b></th>
                              <th><b>Reseller</b></th>
                              <th>
                                <b>API</b>
                              </th>
                            </tr>

                            <tr
                              v-if="
                                list.category_id == 2 ||
                                list.category_id == 3 ||
                                list.category_id == 4 ||
                                list.category_id == 6 ||
                                list.category_id == 11
                              "
                            >
                              <td>Discount</td>
                              <td>{{ list.products.amount1 }}%</td>
                              <td>{{ list.products.amount2 }}%</td>
                              <td>{{ list.products.amount3 }}%</td>
                              <td>{{ list.products.amount4 }}%</td>

                              
                            </tr>
                      
                            <tr
                              v-else-if="
                                list.id == 55 || list.id == 56 || list.id == 57 || list.id == 58 || list.id == 59 || list.id == 61 || list.id == 62"
                            >
                              <td>Discount</td>
                              <td>{{ list.products.amount1 }}%</td>
                              <td>{{ list.products.amount2 }}%</td>
                              <td>{{ list.products.amount3 }}%</td>
                              <td>{{ list.products.amount4 }}%</td>

                              
                            </tr>

                            <tr
                              v-else
                              v-for="product in list.products[0].data"
                              :key="product.plan"
                            >
                              <td class="product_plan">
                                {{ product.plan }}
                              </td>
                              <td>
                                &#8358;{{ formatNumber(product.amount1) }}
                              </td>
                              <td>
                                &#8358;{{ formatNumber(product.amount2) }}
                              </td>
                              <td>
                                &#8358;{{ formatNumber(product.amount3) }}
                              </td>
                              <td>
                                &#8358;{{ formatNumber(product.amount4) }}
                              </td>
                            </tr>
                          </table>
                          <div class="pricing__btn-box">
                            <a href="/login" class="thm-btn pricing__btn"
                              >Buy Now</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--tab-->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import numeral from "numeral";
export default {
  props: ['pages'],
  name: "PricingView",
  data() {
    return {
      lists: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.isLoading = true;
      this.$axios
        .get("get-subcategory-list/")
        .then((response) => {
          // console.log(response.data);
          this.lists = response.data.data;
          this.isLoading = false;
          // this.manual_details = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },
    makeImage(urlString) {
      return process.env.VUE_APP_IMAGE_URL + urlString;
    },
    formatNumber(numberString) {
      return numeral(numberString).format("0,0");
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
  word-wrap: break-word !important;
}
table > tr:first-child tr:last-child td:first-child td:last-child {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

.title_image {
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .product_plan {
    inline-size: 70px !important;
    overflow-wrap: break-word !important;
  }
}

.pricing-title {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 50px;

}
.section-title__title {
  font-size: 20px
}
.lv-progress-spinner {
  width: 100% !important;
}
.progress-spinner-demo-wrapper {
  display: flex;
  flex-flow: row wrap;
  /* height: 150px; */
  justify-content: center;
  align-items: center;
  width: 80%;
}


@media (max-width: 450px) {
  .progress-spinner-demo-wrapper {
    height: max-content;
  }
}
</style> 