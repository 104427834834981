<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <LoadingSpinner v-if="pageLoading"/>
    <div v-else class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img
                v-if="network_image"
                :src="network_image"
                alt="Airtime Logo"
              />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <div class="instruction" v-html="instruction"></div>

            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Service Providers</label>
                <select
                  name="network"
                  id="network"
                  v-model="network"
                  @change="handleNetworkChange"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <!-- {% for package in packages %} -->
                  <option
                    v-for="pack in packages"
                    :value="pack.id"
                    :key="pack.key"
                  >
                    {{ pack.title }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>

              <div class="form-group">
                <label>Phone Number you transferred from </label>
                <input
                  type="text"
                  name="phone"
                  v-model="phone"
                  @keyup="validateInput"
                  class="form-control"
                />
                <span class="text-danger" v-if="!isValidPhoneNumber">Phone number is invalid</span>
              </div>
              <div class="form-group">
                <label> Amount Transferred</label>
                <input
                  name="amount_transferred"
                  type="number"
                  v-model="amount_transferred"
                  @keyup="getAmountToReceive"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label> Amount to Receive</label>
                <input
                  name="amount_to_receive"
                  id="amount_to_receive"
                  type="number"
                  v-model="amount_to_receive"
                  readonly
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label> Our Percentage</label>
                <input
                  name="our_percentage"
                  id="our_percentage"
                  type="number"
                  v-model="our_percentage"
                  readonly
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <button class="btn btn-primary" :disabled="isLoading">
                  {{ isLoading ? "Processing..." : "Process" }}
                </button>
              </div>
              <router-link to="/dashboard/transactions" class="nav-link"
                ><i class="fas fa-fw fa-th"></i>
                <span>View Transactions</span></router-link
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AirtimeToCash",
  data() {
    return {
      network: "",
      amount_transferred: 0,
      amount_to_receive: "",
      phone: "",
      our_percentage: "",
      instruction: "",
      discount: 0,
      amount_charged_text: "",
      network_image: "",
      errors: [],
      success_flag: "",
      packages: {},
      isLoading: false,
      pageLoading: false,
      isValidPhoneNumber: true,
    };
  },
  mounted() {
    this.getPackages();
  },
  methods: {
    validateInput(event) {
      const validationRegex = /^[+]?[0-9]*$/;
      if (this.phone.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    getPackages() {
      this.pageLoading = true;
      this.$axios
        .get("get-airtime-to-cash-packages/")
        .then((response) => {
          // console.log(response.data.data);
          this.packages = response.data.data;
          this.pageLoading = false;
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
            this.errors.push(`${error.response.data.errors[0].detail}`);

            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
          this.pageLoading = false;
        });
    },

    handleNetworkChange() {
      this.amount_transferred = 0;
      this.amount_to_receive = "";
      this.phone = "";
      this.errors = [];
      this.success_flag = "";

      const subcategory_id = this.network;
      if (this.network != "") {
        this.$axios
          .get("get-airtime-to-cash-plans/" + subcategory_id + "/")
          .then((response) => {
            console.log(response);
            // discount = response.data.amount;
            this.our_percentage = response.data.data[0].amount;
            this.instruction = response.data.data[0].description;
            this.network_image =
              process.env.VUE_APP_IMAGE_URL + response.data.data[0].image;
            // console.log(this.discount)
            // this.amount_charged_text = this.getDiscount(this.amt,response.data.data.amount)
          });
      } else {
        this.network_image = "";
      }
      // console.log(this.amount_charged_text);
    },

    getAmountToReceive() {
      this.amount_to_receive = Math.ceil(
        this.amount_transferred -
          this.amount_transferred * (this.our_percentage / 100)
      );
    },
    submitForm(e) {
      this.errors = [];
      this.success_flag = "";
      if (this.phone == "") {
        this.errors.push("Phone number is required!");
        this.notify.error("Phone number is required!");
      } else if (this.amount_transferred == "") {
        this.errors.push("Amount Transferred is required!");
        this.notify.error("Amount Transferred is required!");
      } else if (this.amount_transferred < 1000) {
        this.errors.push("Amount can not be less than ₦1000");
        this.notify.error("Amount can not be less than ₦1000");
      } else if (this.network == []) {
        this.errors.push("Select a provider from the list");
        this.notify.error("Select a provider from the list");
      } else {
        const formData = {
          subcategory_id: this.network,
          phone_number: this.phone,
          amount_transferred: this.amount_transferred,
          amount_to_receive: this.amount_to_receive,
        };
        this.isLoading = true;

        this.$axios
          .post("submit-airtime-to-cash/", formData)
          .then((response) => {
            // console.log(response);
            this.success_flag = response.data.message;
            this.notify.success(response.data.message);
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.network-logo {
  text-align: center;
  margin-bottom: 20px;
}
.network-logo img {
  width: 100px;
}
.network-error {
  color: red;
}
.notification {
  margin: 10px 0 10px;
}
.card-body {
  padding: 30px;
}
label {
  font-weight: bold;
}
input {
  margin-bottom: 20px;
}
#amount_to_receive {
  pointer-events: none;
}
#our_percentage {
  pointer-events: none;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}

.nav-link {
  color: #0a8aaf;
}

</style>