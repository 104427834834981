<template>
  <div>
    <section class="page-header"></section>
    <section class="sign-in">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-6 col-lg-9 col-md-10">
            <div class="sign-in__single">
              <h3 class="sign-in__title">Login</h3>

              <form class="sign-in__form" @submit.prevent="submitForm">
                <div class="sign-in__form-input-box">
                  <input
                    type="email"
                    placeholder="Email address*"
                    required
                    v-model="email"
                  />
                </div>
                <div class="sign-in__form-input-box me">
                  <input
                    v-if="showPassword"
                    type="text"
                    placeholder="Password*"
                    required
                    v-model="password"
                  />
                  <input
                    v-else
                    type="password"
                    placeholder="Password*"
                    required
                    v-model="password"
                  />
                  <span @click="toggleShow" class="togglePassword"
                    ><i
                      class="fa"
                      :class="{
                        'fa-eye-slash': !showPassword,
                        'fa-eye': showPassword,
                      }"
                    ></i
                  ></span>
                </div>

                <div class="checked-box">
                  <!-- <input
                    type="checkbox"
                    name="skipper2"
                    id="skipper2"
                    checked=""
                  />
                  <label for="skipper2"><span></span>Remember Me?</label> -->
                </div>
                <div class="col-lg-12">
                  <div class="notification" v-if="errors.length">
                    <p
                      v-for="error in errors"
                      v-bind:key="error"
                      class="alert alert-danger"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
                <div class="sign-in__form-btn-box">
                  <button
                    type="submit"
                    :disabled="isLoading"
                    class="thm-btn sign-in__form-btn"
                  >
                    {{ isLoading ? "Authenticating... Please wait." : "Login" }}
                  </button>
                </div>
                <br />
                <div class="sign-in__form-btn-box forgot-section">
                  <div class="sign-in__form-forgot-password fg-left">
                    <router-link to="/forgot-password" class="forgot"
                      ><strong>Forgot Password?</strong></router-link
                    >
                  </div>

                  <div class="sign-in__form-forgot-password fg-right">
                    Don't have an account?
                    <router-link to="/register" class="forgot"
                      ><strong>Register</strong></router-link
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import auth from '@/api_provider/Auth'

export default {
  name: "LoginView",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      errors: [],
      isLoading: false,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    passwordToggle() {
      this.password.type = "text";
    },
    async submitForm(e) {
      this.errors = [];

      if (this.email == "" || this.password == "") {
        this.errors.push("Invalid Login");
        this.notify.error("Invalid Login");
      } else {
        const formData = {
          email: this.email,
          password: this.password,
        };

        //   console.log(formData)
        this.isLoading = true;
        this.$axios
          .post("auth/login/", formData)
          .then((response) => {
            // console.log(response.data.data.user);
            const token = response.data.data.tokens.access;
            const refresh = response.data.data.tokens.refresh;
            auth.login(token,refresh);
            this.notify.success("Logged in successfully");
            this.isLoading = false;
            setTimeout(() => this.$router.push({ name: "dashboard" }), 3500);
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data.errors) {
                this.errors.push(
                  `${error.response.data.errors[property]["detail"]}`
                );
                this.notify.error(
                  `${error.response.data.errors[property]["detail"]}`
                );
              }
              // console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.isLoading = false;
          });
      }
      // Profile details
    },
   
  },
};
</script>

<style scoped>
.fg {
  text-align: right;
}
label {
  font-weight: bold;
}
.me {
  display: flex;
  align-items: center;
}
.togglePassword {
  position: absolute;
  right: 0;
  margin-right: 20px;
}
.forgot-section {
  margin-left: 0;
  justify-content: space-between;
}
.fg-left {
  margin-left: 0;
  justify-content: left;
}
.fg-right {
  justify-content: space-between;
}
</style>