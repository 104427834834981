<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <LoadingSpinner v-if="pageLoading" />
    <div v-else class="row">
      <div class="col-lg-12 col-xl-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body table-responsive">
            <p><b>Profile Info</b></p>
            <hr />
            <table class="table table-bordered" style="overflow-x: auto">
              <tr>
                <td>Name</td>
                <td>{{ user.first_name }} {{ user.last_name }}</td>
              </tr>
              <tr>
                <td>Wallet Balance</td>
                <td>&#8358;{{ $filters.formatNumber(user.wallet_balance) }}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{{ user.phone_no }}</td>
              </tr>
              <tr>
                <td>Date Joined</td>
                <td>
                  {{ $filters.formatDate(user.date_joined) }}
                </td>
              </tr>
              <tr>
                <td>Referral Link</td>
                <td>
                  {{ user.referral_link }}
                </td>
              </tr>
              <tr>
                <td>Bvn verification</td>
                <td v-if="user.bvn != null" class="text-success">
                  Bvn Verified!
                </td>
                <td v-else>
                  <router-link
                    to="/dashboard/identity-verification"
                    class="text-success"
                    >Click Here</router-link
                  >
                  to verify your bvn.
                </td>
              </tr>
              <tr>
                <td>Nin verification</td>
                <td v-if="user.nin != null" class="text-success">
                  Nin Verified!
                </td>
                <td v-else>
                  <router-link
                    to="/dashboard/identity-verification"
                    class="text-success"
                    >Click Here</router-link
                  >
                  to verify your nin.
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div class="card-body">
            <form @submit.prevent="updateWebhook">
              <div class="form-group">
                <label>Webhook Url</label>
                <input
                  type="url"
                  class="form-control"
                  name="webhook_url"
                  v-model="user.webhook_url"
                />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary">
                  {{ isLoading2 ? "Processing.." : "Save" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row m">
      <div class="col-lg-6">
        <!-- Form Basic -->
        <div class="card mb-6">
          <div class="card-body">
            <p><b>Change your Password</b></p>
            <hr />
            <div
              class="notification alert alert-success"
              v-if="password_success_flag"
            >
              <span>{{ password_success_flag }}</span>
            </div>
            <div
              class="notification alert alert-danger"
              v-if="password_errors.length"
            >
              <span
                v-for="password_error in password_errors"
                v-bind:key="password_error"
                >{{ password_error }}</span
              >
            </div>
            <form @submit.prevent="submitPassword">
              <div class="form-group">
                <label>Old Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  v-model="old_password"
                />
              </div>
              <div class="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  v-model="new_password"
                />
              </div>
              <div class="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  v-model="confirm_password"
                />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary">
                  {{ isLoading2 ? "Processing..." : "Save" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-6">
          <div class="card-body">
            <p><b>Update your bank Info</b></p>
            <hr />
            <div
              class="notification alert alert-success"
              v-if="bank_success_flag"
            >
              <span>{{ bank_success_flag }}</span>
            </div>
            <div
              class="notification alert alert-danger"
              v-if="bank_errors.length"
            >
              <span v-for="bank_error in bank_errors" v-bind:key="bank_error">{{
                bank_error
              }}</span>
            </div>
            <form @submit.prevent="submitBank">
              <div class="form-group">
                <label>Account Name</label>
                <input
                  type="text"
                  v-model="user.account_name"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Account Number</label>
                <input
                  type="text"
                  v-model="user.account_number"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Bank Name</label>
                <input
                  type="text"
                  v-model="user.bank_name"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary">
                  {{ isLoading ? "Processing..." : "Save" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["user"],
  name: "Profile",
  data() {
    return {
      // user: {},
      picked: "",
      bvn: "",
      bvn_name: "",
      bvn_errors: [],
      bvn_success_flag: "",
      nin: "",
      nin_errors: [],
      nin_success_flag: "",
      old_password: "",
      new_password: "",
      confirm_password: "",
      bank_errors: [],
      bank_success_flag: "",
      password_errors: [],
      password_success_flag: "",
      isLoading: false,
      isLoading2: false,
      pageLoading: false,
    };
  },

  // mounted(){
  //   this.pageLoading = true;
  //     this.$axios
  //       .get("user/profile/")
  //       .then((response) => {
  //         this.user = response.data.data;
  //         auth.setUser(response.data.data)
  //         this.pageLoading = false;
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           // console.log(JSON.stringify(error.response.data));
  //           if (error.response.data.errors[0].code == "authentication_failed") {
  //             // Auth().logout()
  //             // Notify().error("Session Expired!, please login again");
  //             // setTimeout(() => this.$router.push({ name: "login" }), 3500);
  //           }
  //         } else if (error.message) {
  //           console.log(JSON.stringify(error.message));
  //         } else {
  //           console.log(JSON.stringify(error));
  //         }
  //         this.pageLoading = false;
  //       });
  // },

  methods: {
    updateWebhook(e) {
      const webhook_url = this.user.webhook_url;
      if (webhook_url == "") {
        this.notify.error("Webhook Url is required!");
      } else {
        const formData = {
          webhook_url: webhook_url,
        };
        // console.log(formData);
        this.isLoading = true;

        this.$axios
          .put("user/", formData)
          .then((response) => {
            console.log(response);
            this.notify.success(response.data.message);

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.notify.error(error.response.data.message);
              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    submitBank(e) {
      this.bank_errors = [];
      this.bank_success_flag = "";

      const account_name = this.user.account_name;
      const account_number = this.user.account_number;
      const bank_name = this.user.bank_name;

      if (account_name == "") {
        this.bank_errors.push("Account Name is required!");
        this.notify.error("Account Name is required!");
      } else if (account_number == "") {
        this.bank_errors.push("Account Number is required!");
        this.notify.error("Account Number is required!");
      } else if (bank_name == "") {
        this.bank_errors.push("Bank Name is required!");
        this.notify.error("Bank Name is required!");
      } else {
        const formData = {
          account_name: account_name,
          account_number: account_number,
          bank_name: bank_name,
        };
        // console.log(formData);
        this.isLoading = true;

        this.$axios
          .put("user/", formData)
          .then((response) => {
            // console.log(response);
            this.bank_success_flag = response.data.message;
            this.notify.success(response.data.message);
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.bank_errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);
              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    submitPassword(e) {
      this.password_errors = [];
      this.password_success_flag = "";
      this.password_error_flag = "";

      const old_password = this.old_password;
      const new_password = this.new_password;
      const confirm_password = this.confirm_password;

      if (old_password == "") {
        this.password_errors.push("Old password is required!");
        this.notify.error("Old password is required!");
      } else if (new_password == "") {
        this.password_errors.push("New password is required!");
        this.notify.error("New password is required!");
      } else if (confirm_password == "") {
        this.password_errors.push("Confirm password is required!");
        this.notify.error("Confirm password is required!");
      } else if (confirm_password != new_password) {
        this.password_errors.push("Password does not match");
        this.notify.error("Password does not match");
      } else {
        const formData = {
          old_password: old_password,
          password: new_password,
          password2: confirm_password,
        };
        // console.log(formData);
        this.isLoading2 = true;

        this.$axios
          .put("user/change-password/", formData)
          .then((response) => {
            // console.log(response);

            this.password_success_flag = response.data.message;
            this.notify.success(response.data.message);

            this.isLoading2 = false;
          })
          .catch((error) => {
            this.isLoading2 = false;
            console.log(error.response.data.message);
            if (error.response) {
              this.password_errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.m {
  margin-bottom: 50px;
}
label {
  font-weight: bold;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}
.bvn_style,
.nin_style {
  padding: 0 20px 20px;
}
.fmi {
  margin-bottom: 20px;
}
</style>