<template>
  <div>
    <section class="page-header"></section>

    <section class="contact-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="contact-page__center">
              <div class="section-title text-center">
                <div class="section-sub-title-box">
                  <p class="section-sub-title">Privacy Policy</p>
                  <div class="section-title-shape-1">
                    <img
                      src="@/assets/frontend/images/shapes/section-title-shape-1.png"
                      alt=""
                    />
                  </div>
                  <div class="section-title-shape-2">
                    <img
                      src="@/assets/frontend/images/shapes/section-title-shape-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div class="contact_page">
                <div class="privacy" v-html="pages.privacy"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {
  props: ["pages"],
  name: "PrivacyView",
};
</script>
  
  <style scoped>
.section-title {
  margin-bottom: 0;
}
.contact_page {
  text-align: justify;
  margin: 0 20px;
}
.privacy {
  padding-bottom: 20px;
} 
</style>