<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <LoadingSpinner v-if="pageLoading"/>
    <div v-else class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img
                v-if="network_image"
                :src="network_image"
                alt="Airtime Logo"
              />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>
            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->

              <div class="form-group">
                <label> User Level</label>
                <select
                  name="packages"
                  v-model="user_level"
                  class="form-control"
                  required
                >
                  <option value="">Select Level</option>
                  <!-- {% for package in packages %} -->
                  <option
                    v-for="level in packages"
                    :value="level"
                    :key="level.key"
                  >
                    {{ level.title }} at &#8358;{{ level.amount }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>

              <div class="form-group">
                <button class="btn btn-primary" :disabled="isLoading">
                  {{ isLoading ? "Processing..." : "Upgrade/Downgrade" }}
                </button>
               
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "Upgrade",
  data() {
    return {
      network_image: "",
      errors: [],
      success_flag: "",
      packages: {},
      user_level: {},
      isLoading: false,
      pageLoading: false,
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    
    getPackages() {
      this.pageLoading = true;
      this.$axios
        .get("user/level/")
        .then((response) => {
          // console.log(this.profile.user_level);
          // console.log(response.data.data.products)
          // const index = response.data.data.products.indexOf(this.profile.user_level)
          // console.log(index)

          this.packages = response.data.data.products;

          // console.log(response.data.data.products)

          // console.log(indexOf(response.data.data.products['title']=="Normal"))
          // this.packages = response.data.data.products;
          this.pageLoading = false;
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
            this.errors.push(error.response.data.message);
            this.notify.error(error.response.data.message);
            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
          this.pageLoading = false;
        });
    },
    translateLevel(title) {
      switch (title) {
        case "Normal":
          return 0;
          break;
        case "Agent":
          return 1;
          break;
        case "Reseller":
          return 2;
          break;
        case "API":
          return 3;
          break;
        default:
          return 0;
          break;
      }
    },
    submitForm(e) {
      this.errors = [];
      this.success_flag = "";
      if (this.packages == "") {
        this.errors.push("Select a level from the list");
        this.notify.error("Select a level from the list");
      } else {
        const formData = {
          amount: this.user_level.amount,
          user_level: this.translateLevel(this.user_level.title),
        };
        // console.log(formData)
        this.isLoading = true;

        this.$axios
          .post("user/level/", formData)
          .then((response) => {
            // console.log(response);
            this.success_flag = response.data.message;
            this.notify.success(response.data.message);
            // location.href = "/dashboard";

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
  <style scoped>
label {
  font-weight: bold;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}

</style>