<template>
      <router-view></router-view>    
</template>

<script>
export default {
  name: "App",

  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;

    this.$axios.defaults.headers.common["Content-type"] = "application/json";

    // const user = this.$store.state.user
    // const profile = this.$store.state.profile

    if (token) {
      this.$axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      this.$axios.defaults.headers.common["Authorization"] = "";
    }
  },

  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    
  },
};
</script>
<style>
.page-loader {
  width: 100%;
  height: 500px;
  margin: 50px 20px;
}
.hidden {
  display: none !important;
}

</style>