<template>
  <div>
    <section class="page-header"></section>
    <section class="sign-in">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-6 col-lg-9 col-md-10">
            <div class="sign-in__single">
              <h3 class="sign-in__title">Reset Password</h3>
              <div class="col-lg-12">
                <div class="notification" v-if="errors.length">
                  <p
                    v-for="error in errors"
                    v-bind:key="error"
                    class="alert alert-danger"
                  >
                    {{ error }}
                  </p>
                </div>
                <div
                  class="notification alert alert-success"
                  v-if="success_flag"
                >
                  {{ success_flag }}
                </div>
              </div>
              <form class="sign-in__form" @submit.prevent="submitForm">
                <div class="sign-in__form-input-box">
                  <input
                    type="password"
                    placeholder="New Password"
                    required
                    v-model="new_password"
                  />
                </div>
                <div class="sign-in__form-input-box">
                  <input
                    type="password"
                    placeholder="Confirm New Password"
                    required
                    v-model="confirm_new_password"
                  />
                </div>

                <div class="sign-in__form-btn-box">
                  <button
                    v-if="isLoading"
                    type="button"
                    disabled
                    class="thm-btn sign-in__form-btn"
                  >
                    Processing...
                  </button>

                  <button
                    v-else
                    type="submit"
                    class="thm-btn sign-in__form-btn"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>

export default {
  name: "ResetPasswordView",
  data() {
    return {
      new_password: "",
      confirm_new_password: "",
      password_token: "",
      success_flag: "",
      errors: [],
      isLoading: false,
    };
  },
  mounted() {
    // console.log(this.$route.query.token)
    this.password_token = this.$route.query.token;
  },
  methods: {
    submitForm(e) {
      this.errors = [];
      this.success_flag = "";

      if (this.new_password == "") {
        this.errors.push("New Password is required");
        this.notify.error("New Password is required");
      } else if (this.new_confirm_password == "") {
        this.errors.push("Confirm Password is required");
        this.notify.error("Confirm Password is required");
      } else if (this.new_password != this.confirm_new_password) {
        this.errors.push("The two passwords doesn't match");
        this.notify.error("The two passwords doesn't match");
      } else {
        const formData = {
          new_password: this.new_password,
          confirm_new_password: this.confirm_new_password,
          password_token: this.password_token,
        };

        this.isLoading = true;
        // console.log(formData)
        this.$axios
          .post("reset_password/", formData)
          .then((response) => {
            console.log(response);
            this.success_flag = "Password reset done successfully";
            this.notify.success("Password reset done successfully");
            this.isLoading = false;
            setTimeout(() => this.$router.push({ name: "login" }), 3500);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              for (const property in error.response.data.data) {
                this.errors.push(`${error.response.data.data[property]}`);
                this.notify.error(
                  `${error.response.data.errors[property]["detail"]}`
                );
              }
              // console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.isLoading = false;
          });
      }
      // Profile details
    },
  },
};
</script>
  
  <style scoped>
.fg {
  text-align: right;
}
label {
  font-weight: bold;
}
</style>