<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
      </ol>
    </div>

    <!-- <div
      class="modal fade"
      id="infoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabelTopup"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabelTopup">
              Attention
            </h5>
  
            <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          @click="modalClose"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
          </div>
          <div class="modal-body">As Directed by CBN (Central Bank of Nigeria), <br/> It is compulsory that you link up your means of identification before any virtual accounts for wallet funding, Kindly do this to avoid <b>funding</b> issues in future... <br/> To update means of identification <a class="btn btn-primary" href="/dashboard/profile"> click here </a></div>
        </div>
      </div>
    </div> -->

    <!-- {% if notification is not None %} -->
    <LoadingSpinner v-if="pageLoading" />
    <div v-else>
      <div role="alert" class="alert alert-success alert-dismissible notice">
        <button
          type="button"
          data-dismiss="alert"
          aria-label="Close"
          class="close"
        >
          <span aria-hidden="true">×</span>
        </button>
        <i class="fa fa-info"></i>
        <div class="my-notice" v-html="my_notification"></div>
      </div>
      <!-- {% endif %} -->

      <div class="row mb-3">
        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-uppercase mb-1">
                    Wallet Balance
                  </div>

                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    <!-- {{ profile_info }} -->
                    &#8358;{{
                      $filters.formatNumber(user.wallet_balance)
                    }}
                  </div>
                </div>
                <div class="col-auto">
                  <router-link
                    to="/dashboard/fund-wallet"
                    class="btn btn-success"
                    >Fund Wallet</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="space"></div>
        </div>

        <!-- New User Card Example -->
        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-uppercase mb-1">
                    Referral Bonus
                  </div>

                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    &#8358;{{
                      $filters.formatNumber(user.bonus)
                    }}
                  </div>
                </div>
                <div class="col-auto">
                  <router-link
                    to="/dashboard/transfer-bonus"
                    class="btn btn-info"
                    >Transfer</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="space"></div>
        </div>

        <!-- Pending Requests Card Example -->
        <div class="col-xl-4 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-uppercase mb-1">
                    User Level
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{
                      $filters.translateLevel(user.user_level)
                    }}
                  </div>
                  <div class="mt-2 mb-0 text-muted text-xs"></div>
                </div>
                <div class="col-auto">
                  <router-link
                    to="/dashboard/upgrade"
                    class="btn btn-warning"
                    v-if="user.user_level < 1"
                    >Upgrade</router-link
                  >
                  <router-link
                    to="/dashboard/upgrade"
                    class="btn btn-warning"
                    v-else-if="user.user_level > 0"
                    >Downgrade</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-uppercase mb-1">
                    Your Referral Link
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    <a
                      :href="user.referral_link"
                      >{{
                        user.referral_link
                      }}</a
                    >
                  </div>
                  <div class="mt-2 mb-0 text-muted text-xs">
                    <span
                      >Bonuses are added to your wallet balance immediately.
                      Earn up to 5% on transactions carried out by your
                      referral.</span
                    >
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-users fa-2x text-warning"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xl font-weight-bold mb-1">
                    <router-link
                      to="/dashboard/purchase-airtime"
                      class="text-success"
                      >Purchase Airtime</router-link
                    >
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-mobile fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xl font-weight-bold mb-1">
                    <router-link
                      to="/dashboard/purchase-data"
                      class="text-success"
                      >Purchase Data</router-link
                    >
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-wifi fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xl font-weight-bold mb-1">
                    <router-link to="/dashboard/pay-for-tv" class="text-success"
                      >Pay for Cable</router-link
                    >
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-tv fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xl font-weight-bold mb-1">
                    <router-link
                      to="/dashboard/pay-for-electricity"
                      class="text-success"
                      >Pay for Electricity</router-link
                    >
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-bolt fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xl font-weight-bold mb-1">
                    <router-link
                      to="/dashboard/purchase-exam-pins"
                      class="text-success"
                      >Exam Pins</router-link
                    >
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-graduation-cap fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xl font-weight-bold mb-1">
                    <router-link to="/dashboard/send-sms" class="text-success"
                      >Send SMS</router-link
                    >
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-envelope fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-3 col-md-6 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <i class="fas fa-arrow-down fa-2x text-success"></i>
              </div>
              <div class="col mr-2">
                <div class="text-xl font-weight-bold mb-1">
                 <router-link to="/dashboard/withdraw" class="text-success">Withdraw</router-link> 
                </div>
                
              </div>
              
            </div>
          </div>
        </div>
      </div> -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col mr-2">
                  <div class="text-xl font-weight-bold mb-1">
                    <router-link
                      to="/dashboard/transactions"
                      class="text-success"
                      >Transactions</router-link
                    >
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-th fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Row-->
  </div>
  <!---Container Fluid-->
</template>

<script>
import Auth from '@/api_provider/Auth';

export default {
  name: "Dashboard",
  props: ["user"],
  data() {
    return {
      // user: {},
      transactions: [],
      my_notification: "",
      isLoading: false,
      pageLoading: false,
    };
  },
  
  mounted() {
    // this.getUser();
    
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.pageLoading = true;
      this.$axios
        .get("get-settings/")
        .then((response) => {
          // console.log(response.data.data);
          this.my_notification = response.data.data.notification;
          // this.my_notification = notification;
          // this.notify.info(notification);

          this.pageLoading = false;

          // this.manual_details = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);

            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
          this.pageLoading = false;
        });
    },

    // getUser() {
    //   this.pageLoading = true;
    //   this.$axios
    //     .get(`user/`)
    //     .then((response) => {
    //       // console.log("from dashboard layout");
    //       console.log(response.data.data);
    //       localStorage.setItem("expiration", false);
    //       this.user = response.data.data;
    //       this.pageLoading = false;
    //       // this.setUser(response.data.data)
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         console.log("checking");
    //         console.log(JSON.stringify(error.response.data));
    //         if (error.response.data.errors[0].code == "authentication_failed") {
    //           localStorage.setItem("expiration", true);
    //         }
    //       } else if (error.message) {
    //         console.log(JSON.stringify(error.message));
    //       } else {
    //         console.log(JSON.stringify(error));
    //       }
    //     });
    //   this.pageLoading = false;
    // },

    modalClose() {
      $(document).ready(function () {
        $("#infoModal").modal("hide");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.space {
  margin: 50px;
}
a:link {
  text-decoration: none;
}
.alert-success,
.btn-success {
  background-color: #0a8aaf;
}
.btn-success {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}
.text-success {
  color: #0a8aaf !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #0a8aaf !important;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}

.notice {
  display: flex;
  align-items: center;
}
.my-notice {
  margin: 0 0 0 10px;
  padding: 0;
}
</style>