<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img
                v-if="network_image"
                :src="network_image"
                alt="Airtime Logo"
              />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>
            <form @submit.prevent="submitForm">
              <!-- {% csrf_token %} -->

              <div class="form-group">
                <label> Amount</label>
                <input
                  name="amount"
                  type="number"
                  v-model="amount"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <button v-if="isLoading == false" class="btn btn-primary">
                  Withdraw
                </button>
                <button v-else class="btn btn-primary" disabled>
                  Processing...
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Withdraw",
  data() {
    return {
      amount: "",
      network_image: "",
      errors: [],
      success_flag: "",
      isLoading: false,
    };
  },
  methods: {
    submitForm(e) {
      this.errors = [];
      this.success_flag ="";
      if (this.amount == "") {
        this.errors.push("Amount is required");
        this.notify.error("Amount is required");
      }else if (this.amount < 1000) {
        this.errors.push("Amount can not be less than ₦1000");
        this.notify.error("Amount can not be less than ₦1000");
      }else {
        const formData = {
          subcategory_id: this.network,
          amount: this.amount,
        };
        this.isLoading = true;

        this.$axios
          .post("submit-withdraw/", formData)
          .then((response) => {
            // console.log(response);
            this.success_flag = response.data.message;
            this.notify.success(response.data.message);
            
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },

  }
};
</script>
<style scoped>
a:link {
  text-decoration: none;
}
.btn-primary{
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}

</style>