<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <LoadingSpinner v-if="pageLoading"/>
    <div v-else class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="network-logo">
              <img
                v-if="network_image"
                :src="network_image"
                alt="Airtime Logo"
              />
            </div>
            <div class="notification alert alert-success" v-if="success_flag">
              <span>{{ success_flag }}</span>
            </div>
            <div class="notification alert alert-warning" v-if="pending_flag">
              <span>{{ pending_flag }}</span>
            </div>
            <div class="notification alert alert-danger" v-if="errors.length">
              <span v-for="error in errors" v-bind:key="error">{{
                error
              }}</span>
            </div>

            <form @submit.prevent>
              <!-- {% csrf_token %} -->
              <div class="form-group">
                <label>Service Providers</label>
                <select
                  name="network"
                  id="network"
                  v-model="network"
                  @change="handleNetworkChange"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <!-- {% for package in packages %} -->
                  <option
                    v-for="pack in packages"
                    :value="pack.id"
                    :key="pack.key"
                  >
                    {{ pack.title }}
                  </option>
                  <!-- {% endfor %} -->
                </select>
              </div>
              <LoadingPlan v-if="loadingPlan"/>
              <div class="form-group">
                <label> Amount</label>
                <input
                  name="amt"
                  type="number"
                  id="amt"
                  v-model="amt"
                  @keyup="getDiscount"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>Phone </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  v-model="phone"
                  @keyup="validateInput"
                  class="form-control"
                />
                <span class="text-danger" v-if="!isValidPhoneNumber">Phone number is invalid</span>
              </div>
              
              <div
                v-if="amount_charged_text"
                style="margin-bottom: 10px; color: #0a8aaf"
              >
                {{ amount_charged_text }}
              </div>
              <div
                v-if="bank_charged_text"
                style="margin-bottom: 10px; color: #0a8aaf"
              >
                {{ bank_charged_text }}
              </div>

              <div class="form-group">
                <button
                  type="submit"
                  @click="Process('wallet')"
                  class="btn btn-primary"
                  :disabled="isLoading"
                >
                  {{ isLoading ? "Processing..." : "Process" }}
                </button>

                or
                <button
                  type="submit"
                  @click="Process('bank')"
                  class="btn btn-primary"
                  :disabled="isLoading2"
                >
                  {{ isLoading2 ? "Processing..." : "Pay with Bank" }}
                </button>
              </div>
              <router-link to="/dashboard/transactions" class="nav-link"
                ><i class="fas fa-fw fa-th"></i>
                <span>View Transactions</span></router-link
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---Container Fluid-->
</template>

<script>
export default {
  props:['user'],
  name: "Airtime",
  data() {
    return {
      network: "",
      amt: "",
      bank_amt: "",
      phone: "",
      discount: 0,
      amount_charged_text: "",
      bank_charged_text: "",
      network_image: "",
      errors: [],
      success_flag: "",
      pending_flag: "",
      packages: {},
      trans_reference: "",
      isLoading: false,
      isLoading2: false,
      rave_bank_charge: process.env.VUE_APP_FLW_BANK_CHARGE,
      rave_public_Key: process.env.VUE_APP_FLW_PUBLIC_KEY,
      pageLoading: false,
      loadingPlan: false,
      isValidPhoneNumber: true,
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    validateInput(event) {
      const validationRegex = /^[+]?[0-9]*$/;
      if (this.phone.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    getPackages() {
      this.pageLoading = true;
      this.$axios
        .get("get-airtime-packages/")
        .then((response) => {
          // console.log(response);
          this.packages = response.data.data;
          this.pageLoading = false;
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
            this.errors.push(error.response.data.message);

            // console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
          this.pageLoading = false;
        });
    },

    handleNetworkChange() {
      this.loadingPlan = true
      this.amt = "";
      this.amount_charged_text = "";
      const subcategory_id = this.network;
      if (this.network != "") {
        this.$axios
          .get(`get-airtime-plans/${subcategory_id}/`)
          .then((response) => {
            // console.log(response);
            // discount = response.data.amount;
            this.discount = response.data.data[0].amount;
            this.network_image =
              process.env.VUE_APP_IMAGE_URL + response.data.data[0].image;
              this.loadingPlan = false;
            // console.log(this.discount)
            // this.amount_charged_text = this.getDiscount(this.amt,response.data.data.amount)
          })
          .catch((error) => {
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);
              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
            this.loadingPlan = false
          });
      } else {
        this.network_image = "";
      }
      // console.log(this.amount_charged_text);
    },
    getDiscount() {
      var amount_charged = (
        this.amt -
        this.amt * (this.discount / 100)
      ).toFixed(2);

      this.amount_charged_text =
        "You will be charged ₦" + amount_charged.toString();

      var bank_charge = (
        this.amt +
        this.amt * (this.rave_bank_charge / 100)
      ).toFixed(2);
      this.bank_charged_text =
        "Pay with bank charge ₦" + bank_charge.toString();
      this.bank_amt = bank_charge;

      // console.log(this.amount_charged_text);
    },
    Process(action) {
      if (action == "wallet") {
        this.payFromWallet();
      } else {
        this.payFromBank();
      }
    },

    payFromWallet() {
      this.errors = [];
      this.success_flag = "";
      this.pending_flag = "";

      if (this.phone == "") {
        this.errors.push("Phone number is required!");
        this.notify.error("Phone number is required");
      } else if (this.amt == "") {
        this.errors.push("Amount is required!");
        this.notify.error("Amount is required");
      } else if (this.amt < 100) {
        this.errors.push("Amount can not be less than ₦100");
        this.notify.error("Amount can not be less than ₦100");
      } else if (this.network == []) {
        this.errors.push("Select a provider from the list");
        this.notify.error("Select a provider from the list");
      } else {
        const formData = {
          subcategory_id: this.network,
          phone_number: this.phone,
          amount: this.amt,
        };
        this.isLoading = true;

        this.$axios
          .post("purchase-airtime/", formData)
          .then((response) => {
            // console.log(response);
            if (response.status == 200) {
              this.success_flag = response.data.message;
              this.notify.success(response.data.message);
            } else if (response.status == 201) {
              this.pending_flag = response.data.message;
              this.notify.pending(response.data.message);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    payFromBank() {
      this.errors = [];
      this.success_flag = "";
      this.pending_flag = "";

      if (this.phone == "") {
        this.errors.push("Phone number is required!");
        this.notify.error("Phone number is required");
      } else if (this.amt == "") {
        this.errors.push("Amount is required!");
        this.notify.error("Amount is required");
      } else if (this.amt < 100) {
        this.errors.push("Amount can not be less than ₦100");
        this.notify.error("Amount can not be less than ₦100");
      } else if (this.network == []) {
        this.errors.push("Select a provider from the list");
        this.notify.error("Select a provider from the list");
      } else {
        const formData = {
          subcategory_id: this.network,
          phone_number: this.phone,
          amount: this.amt,
        };
        this.isLoading2 = true;
        this.$axios
          .post("initiate-order-airtime/", formData)
          .then((response) => {
            // console.log(response.data);
            this.trans_reference = response.data.data.trans_ref;
            // console.log(this.trans_reference);
            // this.isLoading = false;

            // Make Payment
            this.makePayment();
          })
          .catch((error) => {
            this.isLoading2 = false;
            // console.log(error.response);
            if (error.response) {
              this.errors.push(error.response.data.message);
              this.notify.error(error.response.data.message);

              // console.log(JSON.stringify(error.response.data.errors[0].detail));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    makePayment() {
      FlutterwaveCheckout({
        public_key: "FLWPUBK-269b4e32d162e2a34d6c8d5a9dcd7e70-X",
        tx_ref: this.trans_reference,
        amount: this.bank_amt,
        currency: "NGN",
        payment_options: "banktransfer,card, account, ussd",
        // redirect_url: "https://glaciers.titanic.com/handle-flutterwave-payment",

        customer: {
          email: this.user.email,
          phone_number: this.user.phone_no,
          name: this.user.first_name + " " + this.user.last_name,
        },
        customizations: {
          title: "Topupwave",
          description: "Buy Airtime",
          //logo: "https://www.logolynx.com/images/logolynx/22/2239ca38f5505fbfce7e55bbc0604386.jpeg",
        },
        callback: (payment) => {
          // Send AJAX verification request to backend
          this.loading = false;

          // console.log(payment);
          if (payment.status == "completed") {
            this.success_flag = "Transaction will be processed shortly";
            this.notify.success("Transaction will be processed shortly");
          }

          // console.log(verifyTransactionOnBackend(payment.id));
        },

        onclose: (incomplete) => {
          // console.log(incomplete)
          // this.verifyTransaction(incomplete);

          // this.verifyTransaction(incomplete);

          if (incomplete === false) {
            this.success_flag = "Transaction will be processed shortly";
            this.notify.success("Transaction will be processed shortly");
            this.isLoading2 = false;
          } else {
            this.isLoading2 = false;
            this.errors.push("Transaction failed!");
            this.notify.error("Transaction failed!");
          }
        },
      });

      // console.log(t);
    },
  },
};
</script>

<style scoped>
.network-logo {
  text-align: center;
  margin-bottom: 20px;
}
.network-logo img {
  width: 100px;
}
.network-error {
  color: red;
}
.notification {
  margin: 10px 0 10px;
}
.card-body {
  padding: 30px;
}
label {
  font-weight: bold;
}
input {
  margin-bottom: 20px;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}
.nav-link {
  color: #0a8aaf;
}

</style>