<template>
  <div>
    <section class="main-slider clearfix">
    
      <div
        class="swiper-container thm-swiper__slider"
        data-swiper-options='{"slidesPerView": 1, "loop": true,
              "effect": "fade",
              "pagination": {
              "el": "#main-slider-pagination",
              "type": "bullets",
              "clickable": true
              },
              "navigation": {
              "nextEl": "#main-slider__swiper-button-next",
              "prevEl": "#main-slider__swiper-button-prev"
              },
              "autoplay": {
              "delay": 5000
              }}'
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="slide in slides" :key="slide.id">
            <div
              class="image-layer"
              :style="{ backgroundImage: 'url('+ $filters.makeSlider(slide.image) +')' }"
            ></div>
            <!-- /.image-layer -->
  
            <div class="main-slider-shape-1 float-bob-x">
              <img
                src="@/assets/frontend/images/shapes/main-slider-shape-1.png"
                alt=""
              />
            </div>
  
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="main-slider__content">
                    <h2 class="main-slider__title">
                      {{ slide.title}}
                    </h2>
              
                    <p class="main-slider__text" v-html="slide.description">
                    </p>
                    <div class="main-slider__btn-box">
                      <router-link to="/register" class="thm-btn main-slider__btn"
                        >Let’s Get Started</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
         
        </div>
  
        <!-- If we need navigation buttons -->
        <div class="main-slider__nav">
          <div
            class="swiper-button-prev"
            id="main-slider__swiper-button-next"
          >
            <i class="icon-right-arrow"></i>
          </div>
          <div
            class="swiper-button-next"
            id="main-slider__swiper-button-prev"
          >
            <i class="icon-right-arrow1"></i>
          </div>
        </div>
      </div>
    </section>
<section class="feature-three">
   <div class="feature-three-shape float-bob-x">
       <img src="@/assets/frontend/images/shapes/feature-three-shape.png" alt="">
   </div>
   <div class="container">
       <div class="row">
           <!--Feature Three Single Start-->
           <div class="col-xl-4 col-lg-4 wow fadeInLeft animated" data-wow-delay="100ms" style="visibility: visible; animation-delay: 100ms; animation-name: fadeInLeft;">
               <div class="feature-three__single">
                   <div class="feature-three__icon">
                       <span class="icon-contract"></span>
                   </div>
                   <div class="feature-three__content">
                       <p class="feature-three__sub-title">24/7 Availability</p>
                       <h3 class="feature-three__title">Our virtual doors are always open</h3>
                   </div>
               </div>
           </div>
           <!--Feature Three Single End-->
           <!--Feature Three Single Start-->
           <div class="col-xl-4 col-lg-4 wow fadeInLeft animated" data-wow-delay="200ms" style="visibility: visible; animation-delay: 200ms; animation-name: fadeInLeft;">
               <div class="feature-three__single">
                   <div class="feature-three__icon">
                       <span class="icon-money-back"></span>
                   </div>
                   <div class="feature-three__content">
                       <p class="feature-three__sub-title">Secure Transactions</p>
                       <h3 class="feature-three__title">Your security is our priority.</h3>
                   </div>
               </div>
           </div>
           <!--Feature Three Single End-->
           <!--Feature Three Single Start-->
           <div class="col-xl-4 col-lg-4 wow fadeInLeft animated" data-wow-delay="300ms" style="visibility: visible; animation-delay: 300ms; animation-name: fadeInLeft;">
               <div class="feature-three__single">
                   <div class="feature-three__icon">
                       <span class="icon-shield"></span>
                   </div>
                   <div class="feature-three__content">
                       <p class="feature-three__sub-title">Payment Options</p>
                       <h3 class="feature-three__title">We offer a range of payment options</h3>
                   </div>
               </div>
           </div>
           <!--Feature Three Single End-->
       </div>
   </div>
</section>

<!--About One Start-->
<section class="about-one">
  
   <div class="container">
     <div class="row">
       <div class="col-xl-4">
         <div class="about-one__left">
           <div
             class="about-one__img-box wow slideInLeft"
             data-wow-delay="100ms"
             data-wow-duration="2500ms"
           >
             <div class="about-one__img">
               <img
                 src="@/assets/frontend/images/resources/logo3.png"
                 alt=""
               />
             </div>
         
             
           
           </div>
         </div>
       </div>
       <div class="col-xl-8">
         <div class="about-one__right">
           <div class="section-title text-left">
             <div class="section-sub-title-box">
               <p class="section-sub-title">About Topupwave</p>
               <div class="section-title-shape-1">
                 <img
                   src="@/assets/frontend/images/shapes/section-title-shape-1.png"
                   alt=""
                 />
               </div>
               <div class="section-title-shape-2">
                 <img
                   src="@/assets/frontend/images/shapes/section-title-shape-2.png"
                   alt=""
                 />
               </div>
             </div>
             
           </div>
           
           
           <p class="about-one__text-2" v-html="String(pages.about_us).substring(0, 700) + '...'">
           </p>
           <div class="about-one__btn-call">
             <div class="about-one__btn-box">
               <router-link to="/about" class="thm-btn about-one__btn"
                 >Discover More</router-link
               >
             </div>
             <div class="about-one__call">
               <div class="about-one__call-icon">
                 <i class="fas fa-phone"></i>
               </div>
               <div class="about-one__call-content">
                 <a :href="pages.phone_link">{{ pages.phone }}</a>
                 <p>Contact us</p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
 <!--About One End-->

     <!--Services One Start-->
     <section class="services-one">
      <div class="services-one__top">
        <div class="container">
          <div class="row">
            <div class="col-xl-5 col-lg-6">
              <div class="services-one__top-left">
                <div class="section-title text-left">
                  <div class="section-sub-title-box">
                    <p class="section-sub-title">Our services</p>
                    <div class="section-title-shape-1">
                      <img
                        src="@/assets/frontend/images/shapes/section-title-shape-1.png"
                        alt=""
                      />
                    </div>
                    <div class="section-title-shape-2">
                      <img
                        src="@/assets/frontend/images/shapes/section-title-shape-2.png"
                        alt=""
                      />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="services-one__bottom">
        <div class="services-one__container">
          <div class="row">
            <!--Services One Single Start-->
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div class="services-one__single">
                <div class="service-one__img">
                  <img src="@/assets/frontend/images/services/services-1.png" alt="" />
                </div>
                <div class="service-one__content">
                  <div class="services-one__icon">
                    <span class="fa fa-mobile"></span>
                  </div>
                  <h2 class="service-one__title">
                    <router-link to="/login">Instant Airtime Recharge</router-link>
                  </h2>
                  <p> Enjoy up 4% discount on all networks</p>
                  
                </div>
              </div>
            </div>
            <!--Services One Single End-->
            <!--Services One Single Start-->
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div class="services-one__single">
                <div class="service-one__img">
                  <img src="@/assets/frontend/images/services/services-2.png" alt="" />
                </div>
                <div class="service-one__content">
                  <div class="services-one__icon">
                    <span class="fa fa-globe"></span>
                  </div>
                  <h2 class="service-one__title">
                    <router-link to="/login">Cheap Data Subscription</router-link>
                  </h2>
                  <p>Enjoy cheap and instant data on all networks</p>
                 
                </div>
              </div>
            </div>
            <!--Services One Single End-->
            <!--Services One Single Start-->
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div class="services-one__single">
                <div class="service-one__img">
                  <img src="@/assets/frontend/images/services/services-3.png" alt="" />
                </div>
                <div class="service-one__content">
                  <div class="services-one__icon">
                    <span class="fa fa-tv"></span>
                  </div>
                  <h2 class="service-one__title">
                    <router-link to="/login">Cable/Tv Subscription</router-link>
                  </h2>
                     <p>No convenience fee, enjoy discount of up to 4% </p>
                </div>
              </div>
            </div>
            <!--Services One Single End-->
            <!--Services One Single Start-->
            <div
              class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div class="services-one__single">
                <div class="service-one__img">
                  <img src="@/assets/frontend/images/services/services-4.png" alt="" />
                </div>
                <div class="service-one__content">
                  <div class="services-one__icon">
                    <span class="fa fa-plug"></span>
                  </div>
                  <h2 class="service-one__title">
                    <router-link to="/login">Electricity Bill Payment</router-link>
                  </h2>
                  <p> No convenience fee, enjoy up 4% discount</p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </section>
    <!--Services One End-->
  
     <!--Why Choose One Start-->
     <section class="why-choose-one">
      <div
        class="why-choose-one-shape-1 back-image"></div>
      <div class="why-choose-one-shape-2 float-bob-y">
        <img src="@/assets/frontend/images/shapes/why-choose-one-shape-2.png" alt="" />
      </div>
      <div class="why-choose-one-shape-3 float-bob-x">
        <img src="@/assets/frontend/images/shapes/why-choose-one-shape-3.png" alt="" />
      </div>
      <div class="why-choose-one-shape-4 float-bob-y">
        <img src="@/assets/frontend/images/shapes/why-choose-one-shape-4.png" alt="" />
      </div>
      <div class="why-choose-one-shape-5 float-bob-y">
        <img src="@/assets/frontend/images/shapes/why-choose-one-shape-5.png" alt="" />
      </div>
      <div class="why-choose-one-shape-6 float-bob-x">
        <img src="@/assets/frontend/images/shapes/why-choose-one-shape-6.png" alt="" />
      </div>
      <div
        class="why-choose-one-img wow slideInRight"
        data-wow-delay="100ms"
        data-wow-duration="2500ms"
      >
        <img src="@/assets/frontend/images/resources/why-choose-one-img.png" alt="" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-7">
            <div class="why-choose-one__left">
              <div class="section-title text-left">
                <div class="section-sub-title-box">
                  <p class="section-sub-title">Steps to Get Started</p>
                  <div class="section-title-shape-1">
                    <img
                      src="@/assets/frontend/images/shapes/section-title-shape-3.png"
                      alt=""
                    />
                  </div>
                  <div class="section-title-shape-2">
                    <img
                      src="@/assets/frontend/images/shapes/section-title-shape-4.png"
                      alt=""
                    />
                  </div>
                </div>
                
              </div>
              <p class="why-choose-one__text">
               Follow the easy steps below to get started!
              </p>
              <div class="why-choose-one__list-box">
                <ul class="list-unstyled why-choose-one__list">
                  <li>
                    <div class="why-choose-one__single">
                      <div class="why-choose-one__list-icon">
                        <span class="icon-easy-to-use"></span>
                      </div>
                      <div class="why-choose-one__list-title-box">
                        <div class="why-choose-one__list-title-inner">
                          <h3 class="why-choose-one__list-title">
                          Register
                          </h3>
                        </div>
                        <div class="why-choose-one__list-text-box">
                          <p class="why-choose-one__list-text">
                           Click on <router-link to="/register">Register</router-link> or from the Top Menu and fill in your details
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="why-choose-one__single">
                      <div class="why-choose-one__list-icon">
                        <span class="icon-contract"></span>
                      </div>
                      <div class="why-choose-one__list-title-box">
                        <div class="why-choose-one__list-title-inner">
                          <h3 class="why-choose-one__list-title">
                            Login
                          </h3>
                        </div>
                        <div class="why-choose-one__list-text-box">
                          <p class="why-choose-one__list-text">
                           Login instantly with the details you submitted during Registration
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="why-choose-one__single">
                      <div class="why-choose-one__list-icon">
                        <span class="icon-policy"></span>
                      </div>
                      <div class="why-choose-one__list-title-box">
                        <div class="why-choose-one__list-title-inner">
                          <h3 class="why-choose-one__list-title">
                            Fund Wallet
                          </h3>
                        </div>
                        <div class="why-choose-one__list-text-box">
                          <p class="why-choose-one__list-text">
                           Fund your wallet instantly and start buying
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="why-choose-one__single">
                      <div class="why-choose-one__list-icon">
                        <span class="icon-fund"></span>
                      </div>
                      <div class="why-choose-one__list-title-box">
                        <div class="why-choose-one__list-title-inner">
                          <h3 class="why-choose-one__list-title">
                           Start buying
                          </h3>
                        </div>
                        <div class="why-choose-one__list-text-box">
                          <p class="why-choose-one__list-text">
                           You can access our services whenever you require them.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--Why Choose One End-->

    <section class="download">
      <div class="download-bg"></div>
      <div class="download-shape-1 float-bob-y">
          <img src="@/assets/frontend/images/shapes/download-shape-1.png" alt="">
      </div>
      <div class="download-shape-2 float-bob-x">
          <img src="@/assets/frontend/images/shapes/download-shape-2.png" alt="">
      </div>
      <div class="container">
          <div class="row">
              <div class="col-xl-7 col-lg-6">
                  <div class="download__left">
                      <h3 class="download__title">Download our mobile application</h3>
                      <div class="download__apps">
                          <div class="download__app-one">
                              <a href="#">
                                  <i class="fa fa-play"></i>
                                  <p> <span>Available on</span> <br> Google Play</p>
                              </a>
                          </div>
                          <!-- <div class="download__app-one download__app-one--two">
                              <a href="#">
                                  <i class="fab fa-apple"></i>
                                  <p> <span>get it on</span> <br> Play Store</p>
                              </a>
                          </div> -->
                      </div>
                  </div>
              </div>
              <div class="col-xl-5 col-lg-6">
                  <div class="download__right">
                      <div class="download__img wow slideInRight animated" data-wow-delay="100ms" data-wow-duration="2500ms" style="visibility: visible; animation-duration: 2500ms; animation-delay: 100ms; animation-name: slideInRight;">
                          <img src="@/assets/frontend/images/resources/download-img-1.png" alt="">
                          
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
    
</div>
</template>

<script>
export default {
  props: ['pages','slides'],
  name: 'Home',
  data() {
    return {
      errors: [],
      loaded: false,
     
    };
  },
  updated() {
    $(".thm-swiper__slider").each(function () {
        let elm = $(this);
        let options = elm.data("swiper-options");
        let thmSwiperSlider = new Swiper(elm, options);
      });
  },
  watch () {
    $(".thm-swiper__slider").each(function () {
        let elm = $(this);
        let options = elm.data("swiper-options");
        let thmSwiperSlider = new Swiper(elm, options);
      });
  },
  mounted() {
    $(".thm-swiper__slider").each(function () {
        let elm = $(this);
        let options = elm.data("swiper-options");
        let thmSwiperSlider = new Swiper(elm, options);
      });
  },

  methods: {
    
  },
}
</script>
<style scoped>
.back-image {
  background-image: url("@/assets/frontend/images/shapes/why-choose-one-shape-1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.download-bg {
  background-image: url("@/assets/frontend/images/backgrounds/download-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>