<template>
  <div class="page-wrapper">
    <header class="main-header clearfix">
      <div class="main-header__top">
        <div class="container">
          <div class="main-header__top-inner">
            <div class="main-header__top-address">
              <ul class="list-unstyled main-header__top-address-list">
                <li>
                  <i class="icon">
                    <span class="icon-pin"></span>
                  </i>
                  <div class="text">
                    <p>{{ pages.address }}</p>
                  </div>
                </li>
                <li>
                  <i class="icon">
                    <span class="icon-email"></span>
                  </i>
                  <div class="text">
                    <p>
                      <a :href="pages.email_link">{{ pages.email }}</a>
                    </p>
                  </div>
                </li>
                <li>
                  <i class="icon">
                    <span class="fa fa-phone"></span>
                  </i>
                  <div class="text">
                    <p>
                      <a :href="pages.phone_link">{{ pages.phone }}</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="main-header__top-right">
              <div class="main-header__top-menu-box">
                <ul class="list-unstyled main-header__top-menu">
                  <li></li>
                  <li><router-link to="/faq"> FAQs</router-link></li>
                  <li><router-link to="/about">About</router-link></li>
                </ul>
              </div>
              <div class="main-header__top-social-box">
                <div class="main-header__top-social">
                  <a :href="pages.twitter"><i class="fab fa-twitter"></i></a>
                  <a :href="pages.facebook"><i class="fab fa-facebook"></i></a>
                  <a :href="pages.instagram"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav class="main-menu main-menu-three clearfix">
        <div class="main-menu-three__wrapper clearfix">
          <div class="container">
            <div class="main-menu-three__wrapper-inner clearfix">
              <div class="main-menu-three__left">
                <div class="main-menu-three__logo">
                  <router-link to="/"
                    ><img
                      src="@/assets/frontend/images/resources/logo.png"
                      alt=""
                  /></router-link>
                </div>
                <div class="main-menu-three__main-menu-box">
                  <router-link to="#" class="mobile-nav__toggler"
                    ><i class="fa fa-bars"></i
                  ></router-link>
                  <ul class="main-menu__list">
                    <li>
                      <router-link to="/">Home </router-link>
                    </li>
                    <li>
                      <router-link to="/about">About </router-link>
                    </li>
                    <li>
                      <router-link to="/pricing">Pricing </router-link>
                    </li>
                    <li>
                      <router-link to="/faq">FAQ </router-link>
                    </li>
                    <li>
                      <router-link to="/contact">Contact </router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="main-menu-three__right">
                <div class="main-menu-three__search-get-quote-btn">
                  <div class="main-menu-three__search-box"></div>
                  <div class="main-menu-three__get-quote-btn-box">
                    <router-link
                      to="/register"
                      class="thm-btn main-menu-three__get-quote-btn"
                      >Register</router-link
                    >
                    &nbsp;
                    <router-link
                      to="/login"
                      class="thm-btn main-menu-three__get-quote-btn"
                      >Login</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <div class="stricky-header stricked-menu main-menu main-menu-three">
      <div class="sticky-header__content">
        <div class="main-menu-three__wrapper clearfix">
          <div class="container">
            <div class="main-menu-three__wrapper-inner clearfix">
              <div class="main-menu-three__left">
                <div class="main-menu-three__logo">
                  <a href="/"
                    ><img
                      src="@/assets/frontend/images/resources/logo.png"
                      alt=""
                  /></a>
                </div>
                <div class="main-menu-three__main-menu-box">
                  <a href="#" class="mobile-nav__toggler"
                    ><i class="fa fa-bars"></i
                  ></a>
                  <ul class="main-menu__list">
                    <li>
                      <router-link to="/">Home </router-link>
                    </li>
                    <li>
                      <router-link to="/about">About </router-link>
                    </li>

                    <li>
                      <router-link to="/pricing">Pricing </router-link>
                    </li>
                    <li>
                      <router-link to="/faq">FAQ </router-link>
                    </li>
                    <li>
                      <router-link to="/contact">Contact </router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="main-menu-three__right">
                <div class="main-menu-three__search-get-quote-btn">
                  <div class="main-menu-three__search-box"></div>
                  <div class="main-menu-three__get-quote-btn-box">
                    <router-link
                      to="/register"
                      class="thm-btn main-menu-three__get-quote-btn"
                      >Register</router-link
                    >&nbsp;
                    <router-link
                      to="/login"
                      class="thm-btn main-menu-three__get-quote-btn"
                      >Login</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.sticky-header__content -->
    </div>
    <router-view :pages="pages" :slides="slides"></router-view>
    <!--Site Footer Start-->
    <footer class="site-footer">
      <div class="site-footer-bg back-image"></div>
      <div class="container">
        <div class="site-footer__top">
          <div class="row">
            <div
              class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div class="footer-widget__column footer-widget__about">
                <div class="footer-widget__logo">
                  <router-link to="/"
                    ><img
                      src="@/assets/frontend/images/resources/logo.png"
                      alt=""
                  /></router-link>
                </div>
                <div class="footer-widget__about-text-box">
                  <p
                    class="footer-widget__about-text"
                    v-html="String(pages.about_us).substring(0, 250) + '...'"
                  ></p>
                </div>
                <div class="site-footer__social">
                  <a :href="pages.twitter"><i class="fab fa-twitter"></i></a>
                  <a :href="pages.facebook"><i class="fab fa-facebook"></i></a>
                  <a :href="pages.instagram"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div
                class="footer-widget__column footer-widget__contact clearfix"
              >
                <h3 class="footer-widget__title">Quick Links</h3>
                <ul class="footer-widget__contact-list list-unstyled clearfix">
                  <li>
                    <router-link to="/about" class="my-link">About</router-link>
                  </li>
                  <li>
                    <router-link to="/faq" class="my-link">Faqs</router-link>
                  </li>

                  <li>
                    <router-link to="/terms-and-conditions" class="my-link"
                      >Terms and Conditions</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/privacy-policy" class="my-link"
                      >Privacy Policy</router-link
                    >
                  </li>
                  <li>
                    <a
                      href="https://documenter.getpostman.com/view/16297490/2sA3QwcqQC"
                      target="_blank"
                      class="my-link"
                      >API Documentation</a
                    >
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div class="footer-widget__column footer-widget__newsletter">
                <h3 class="footer-widget__title">Contact</h3>

                <div class="footer-widget__phone">
                  <div class="footer-widget__phone-icon">
                    <span class="icon-pin"></span>
                  </div>
                  <div class="footer-widget__phone-text">
                    <a href="#">{{ pages.address }}</a>
                    <p>Our address</p>
                  </div>
                </div>
                <div class="footer-widget__phone">
                  <div class="footer-widget__phone-icon">
                    <span class="icon-email"></span>
                  </div>
                  <div class="footer-widget__phone-text">
                    <a :href="pages.email_link">{{ pages.email }}</a>
                    <p>Email us</p>
                  </div>
                </div>
                <div class="footer-widget__phone">
                  <div class="footer-widget__phone-icon">
                    <span class="icon-telephone"></span>
                  </div>
                  <div class="footer-widget__phone-text">
                    <a :href="pages.phone_link">{{ pages.phone }}</a>
                    <p>Call us</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="site-footer__bottom">
          <div class="row">
            <div class="col-xl-12">
              <div class="site-footer__bottom-inner">
                <p class="site-footer__bottom-text">
                  © All Copyright {{ $filters.copyRightDate() }} by Topupwave NG
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {{ $filters.whatsapp(pages.whatsapp_phone) }}
    </footer>
    <!--Site Footer End-->
    <div class="mobile-nav__wrapper">
      <div class="mobile-nav__toggler"></div>
      <!-- /.mobile-nav__overlay -->
      <div class="mobile-nav__content">
        <span class="mobile-nav__close mobile-nav__toggler"
          ><i class="fa fa-times"></i
        ></span>

        <div class="logo-box">
          <a href="/" aria-label="logo image"
            ><img
              src="@/assets/frontend/images/resources/logo.png"
              width="143"
              alt=""
          /></a>
        </div>
        <!-- /.logo-box -->
        <div class="mobile-nav__container">
          <ul class="main-menu__list">
            <li>
              <a href="/">Home </a>
            </li>
            <li>
              <a href="/about">About </a>
            </li>

            <li>
              <a href="/pricing">Pricing </a>
            </li>
            <li>
              <a href="/faq">FAQ </a>
            </li>
            <li>
              <a href="/contact">Contact </a>
            </li>
            <li>
              <a href="/login">Login </a>
            </li>
            <li>
              <a href="/register">Register </a>
            </li>
          </ul>
        </div>
        <!-- /.mobile-nav__container -->

        <ul class="mobile-nav__contact list-unstyled">
          <li>
            <i class="fa fa-envelope"></i>
            <a :href="pages.email_link">{{ pages.email }}</a>
          </li>
          <li>
            <i class="fa fa-phone"></i>
            <a :href="pages.phone_link">{{ pages.phone }}</a>
          </li>
        </ul>
        <!-- /.mobile-nav__contact -->
        <div class="mobile-nav__top">
          <div class="mobile-nav__social">
            <a :href="pages.twitter" class="fab fa-twitter"></a>
            <a :href="pages.facebook" class="fab fa-facebook-square"></a>
            <a :href="pages.instagram" class="fab fa-instagram"></a>
          </div>
          <!-- /.mobile-nav__social -->
        </div>
        <!-- /.mobile-nav__top -->
      </div>
      <!-- /.mobile-nav__content -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontendLayout",
  data() {
    return {
      pages: {},
      slides: [],
    };
  },

  mounted() {
    $(document).ready(function () {
      // Check for click events on the navbar burger icon
      if ($(".mobile-nav__toggler").length) {
        $(".mobile-nav__toggler").on("click", function (e) {
          e.preventDefault();
          $(".mobile-nav__wrapper").toggleClass("expanded");
          $("body").toggleClass("locked");
        });
      }
    });
    this.getSlides()
  },

  created: function () {
    this.$axios.defaults.headers.common["Authorization"] = "";
    this.$axios.defaults.headers.common["Content-type"] = "application/json";
    this.$axios
      .get("get-pages/")
      .then((response) => {
        // console.log(response.data.data.about);
        const about_us = response.data.data.about;
        const contact = response.data.data.contact;
        const contact_data = contact.split("|");
        const phone = contact_data[1];
        const phone_link = "tel:" + contact_data[1];

        const social = response.data.data.social;

        const social_data = social.split("|");

        const address = contact_data[0];
        const email = contact_data[2];

        const whatsapp_phone = response.data.data.whatsapp_phone;

        const email_link = "mailto:" + contact_data[2];

        const facebook = "https://facebook.com/" + social_data[0];
        const instagram = "https://instagram.com/" + social_data[1];
        const twitter = "https://twitter.com/" + social_data[2];

        const faq = response.data.data.faq;
        const privacy = response.data.data.privacy;
        const terms = response.data.data.terms;
        this.pages = {
          about_us: about_us,
          phone: phone,
          phone_link: phone_link,
          whatsapp_phone: whatsapp_phone,
          address: address,
          email: email,
          email_link: email_link,
          facebook: facebook,
          instagram: instagram,
          twitter: twitter,
          faq: faq,
          privacy: privacy,
          terms: terms,
        };
        
      })
      .catch((error) => {
        if (error.response) {
          console.log(JSON.stringify(error.response.data));
        } else if (error.message) {
          console.log(JSON.stringify(error.message));
        } else {
          console.log(JSON.stringify(error));
        }
      });
  },

  methods: {
    getSlides() {
      this.$axios.defaults.headers.common["Authorization"] = "";
      this.loaded = false;
      this.$axios
        .get("/get-sliders/")
        .then((response) => {
          // console.log(response.data.data);
          this.slides = response.data.data;
          this.loaded = true;
          // console.log(response.data.data);
          // for (const property in response.data.data) {
          //       this.slides.push(`${axios.defaults.baseURL + response.data.data[property]['image']}`);
          //   }
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response) {
            this.errors.push(`${error.response.data.errors[0].detail}`);

            console.log(JSON.stringify(error.response.data.errors[0].detail));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },
  }

};
</script>
<style scoped>
ul {
  margin-bottom: 0;
}
a:link {
  text-decoration: none;
}
.my-link {
  color: white;
}
.footer-widget__phone {
  margin-bottom: 20px;
}
</style>