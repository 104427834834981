<template>
  <div>
    <section class="page-header"></section>
    <section class="sign-in">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-6 col-lg-9 col-md-10">
            <div class="sign-in__single">
              <h3 class="sign-in__title">Register</h3>

              <form class="sign-in__form" @submit.prevent="submitForm">
                <div class="sign-in__form-input-box">
                  <input
                    type="text"
                    name="first_name"
                    required
                    v-model="first_name"
                    placeholder="Enter First Name"
                  />
                </div>
                <div class="sign-in__form-input-box">
                  <input
                    type="text"
                    name="last_name"
                    required
                    v-model="last_name"
                    placeholder="Enter Last Name"
                  />
                </div>
                <div class="sign-in__form-input-box">
                  <input
                    type="email"
                    placeholder="Email address*"
                    required
                    :class="{ valid: isValidEmail, invalid: !isValidEmail }"
                    v-model="email"
                  />
                  <div className="text-danger error" v-if="!isValidEmail">
                    Invalid Email Address
                  </div>
                </div>
                <div class="sign-in__form-input-box me">
                  <input
                    v-if="showPassword"
                    type="text"
                    placeholder="Password*"
                    required
                    v-model="password"
                  />
                  <input
                    v-else
                    type="password"
                    placeholder="Password*"
                    required
                    v-model="password"
                  />
                  <span @click="toggleShow" class="togglePassword"
                    ><i
                      class="fa"
                      :class="{
                        'fa-eye-slash': !showPassword,
                        'fa-eye': showPassword,
                      }"
                    ></i
                  ></span>
                  <p className="cite">
                    Password must be a minimum of 8 characters
                  </p>
                </div>
                <div class="sign-in__form-input-box">
                  <input
                    v-if="showPassword2"
                    type="text"
                    name="confirm_password"
                    placeholder="Confirm Password*"
                    required
                    v-model="confirm_password"
                    @keyup="checkPassword"
                  />
                  <input
                    v-else
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm Password*"
                    required
                    v-model="confirm_password"
                    @keyup="checkPassword"
                  />
                  <span @click="toggleShow2" class="togglePassword"
                    ><i
                      class="fa"
                      :class="{
                        'fa-eye-slash': !showPassword2,
                        'fa-eye': showPassword2,
                      }"
                    ></i
                  ></span>
                  <p className="text-danger error" v-if="!isValidPassword">
                    Password does not match
                  </p>
                </div>
                
                <div class="sign-in__form-input-box">
                  <input
                    type="text"
                    name="phone_number"
                    placeholder="Enter Phone Number*"
                    :class="{
                      valid: isValidPhoneNumber,
                      invalid: !isValidPhoneNumber,
                    }"
                    required
                    @keyup="validatePhoneNumber"
                    v-model="phone_number"
                  />
                  <div className="text-danger error" v-if="!isValidPhoneNumber">
                    Invalid Phone Number
                  </div>
                </div>
                <div class="sign-in__form-input-box">
                  <input
                    type="text"
                    name="referral_code"
                    placeholder="Referral Code (Optional) Leave it blank"
                    readonly
                    v-model="referral_code"
                  />
                </div>
                <div class="sign-in__form-input-box">
                  <label for="phone">How did you hear about us*</label>
                  <select
                    name="hear_us"
                    class="form-control sel"
                    v-model="hear_us"
                    required
                  >
                    <option value="">Select One</option>
                    <option>Facebook</option>
                    <option>Instagram</option>
                    <option>Twitter</option>
                    <option>Google</option>
                    <option>From a Friend</option>
                  </select>
                </div>

                <div class="col-lg-12">
                  <div class="notification" v-if="errors.length">
                    <p
                      v-for="error in errors"
                      v-bind:key="error"
                      class="alert alert-danger"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>

                <div class="sign-in__form-btn-box">
                  <button
                    type="submit"
                    class="thm-btn sign-in__form-btn"
                    :disabled="isLoading"
                  >
                    {{ isLoading ? "Creating account... Please wait." : "Create Account" }}
                  </button>
                 
                </div>
                <br />
                <div class="sign-in__form-btn-box">
                  <div
                    class="sign-in__form-forgot-password lg-center"
                    style="margin-left: 0"
                  >
                    Already have an account?
                    <router-link to="/login" class="forgot lg-link"
                      ><strong>Login</strong></router-link
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "RegisterView",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      phone_number: "",
      isValidPhoneNumber: true,
      isValidEmail: true,
      referral_code: "",
      hear_us: "",
      errors: [],
      isLoading: false,
      showPassword: false,
      showPassword2: false,
      isValidPassword: true,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel() {
      return this.showPassword2 ? "Hide" : "Show";
    },
  },
  mounted() {
    this.referral_code =
      this.$route.query.ref != null ? this.$route.query.ref : "";
  },
  watch: {
    email(value) {
      this.validateEmail(value);
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
    checkPassword() {
      if(this.password != this.confirm_password){
        this.isValidPassword = false;
      }else{
        this.isValidPassword = true;
      }
    },

    validatePhoneNumber() {
      // const validationRegex = /^\d{11}$/;
      const validationRegex = /\+?0[0-9]{10}/;
      if (this.phone_number.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },

    validateEmail(value) {
      const validationRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (value.match(validationRegex)) {
        this.isValidEmail = true;
      } else {
        this.isValidEmail = false;
      }
    },

    submitForm(e) {
      this.errors = [];

      const formData = {
        first_name: this.first_name,
        last_name: this.last_name,
        username: this.email,
        email: this.email,
        password: this.password,
        password2: this.confirm_password,
        phone_no: this.phone_number,
        referred_by: this.referral_code,
        hear_us: this.hear_us,
      };

      // console.log(formData)

      if (this.first_name == "") {
        this.errors.push("First name field is required");
        this.notify.error("First name field is required");
      } else if (this.last_name == "") {
        this.errors.push("Last name field is required");
        this.notify.error("Last name field is required");
      } else if (this.email == "") {
        this.errors.push("Email field is required");
        this.notify.error("Email field is required");
      } else if (this.isValidEmail == false) {
        this.errors.push("Invalid email address");
        this.notify.error("Invalid email address");
      } else if (this.password == "") {
        this.errors.push("Password field is required");
        this.notify.error("Password field is required");
      } else if (this.confirm_password == "") {
        this.errors.push("Confirm Password field is required");
        this.notify.error("Confirm Password field is required");
      } else if (this.phone_number == "") {
        this.errors.push("Phone number field is required");
        this.notify.error("Phone number field is required");
      } else if (this.isValidPhoneNumber == false) {
        this.errors.push(
          "Invalid phone number, the format allowed e.g 07012345678, 08012345678 and 11 digits only"
        );
        this.notify.error(
          "Invalid phone number, the format allowed e.g 07012345678, 08012345678 and 11 digits only"
        );
      } else if (this.isValidPassword == false) {
        this.errors.push(
          "Password does not match"
        );
        this.notify.error(
          "Password does not match"
        );
      } else if (this.hear_us == "") {
        this.errors.push("Select one from the list of 'hear us'");
        this.notify.error("Select one from the list of 'hear us'");
      } else {
        this.isLoading = true;
        this.$axios
          .post("user/register/", formData)
          .then((response) => {
            this.isLoading = false;

            this.notify.success("Account created successfully!");

            setTimeout(() => this.$router.push({ name: "login" }), 3500);
          })
          .catch((error) => {
            // console.log(error);
            this.isLoading = false;
            if (error.response) {
              for (const property in error.response.data.data) {
                this.errors.push(
                  `${error.response.data.data[property][property]}`
                );
                this.notify.error(
                  `${error.response.data.data[property][property]}`
                );
              }
              // console.log(JSON.stringify(error.response.data.data));
            } else if (error.message) {
              console.log(JSON.stringify(error.message));
            } else {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
<style scoped>
label {
  font-weight: bold;
}

.togglePassword {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-top: 18px;
  margin-right: 20px;
}
.sel {
  height: calc(1.5em + 1.6rem + 7px) !important;
  background: #f2f5f9;
  border-color: #f2f5f9;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}
.lg-center {
  display: flex;
  justify-content: center;
  width: 100%;
}
.lg-center a {
  margin-left: 5px;
}

.cite {
  font-size: small;
  font-style: italic;
  padding-left: 10px;
}
.error {
  font-size: small;
  font-style: italic;
  padding-left: 10px;
}
</style>