<template>
  <!-- Container Fluid-->
  <div class="container-fluid" id="container-wrapper">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">{{ this.$route.meta.title }}</h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ path: '/dashboard' }"
            >{{ this.$route.meta.home_title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ this.$route.meta.title }}
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xl-9 col-md-12">
        <!-- Form Basic -->
        <div class="card mb-4">
          <div class="card-body">
            <p><b>Form of Identification</b></p>
            <hr />
            <p class="fmi">
              <input
                type="radio"
                id="bvn"
                name="id-select"
                value="bvn"
                v-model="picked"
              />
              Bvn Number <br />
            </p>

            <div v-if="picked == 'bvn'" class="bvn_style">
              <div
                class="notification alert alert-success"
                v-if="bvn_success_flag"
              >
                <span>{{ bvn_success_flag }}</span>
              </div>
              <div
                class="notification alert alert-danger"
                v-if="bvn_errors.length"
              >
                <span v-for="bvn_error in bvn_errors" :key="bvn_error.data">{{
                  bvn_error
                }}</span>
              </div>

              <form @submit.prevent="submitBvn">
                <div class="form-group">
                  <label>BVN number</label>
                  <input
                    type="text"
                    name="bvn"
                    id="bvn"
                    v-model="bvn"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Full Name (as it appears on your bvn)</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    v-model="bvn_name"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <button
                    :disabled="isLoading"
                    id="bvn"
                    class="btn btn-primary"
                  >
                    {{ isLoading ? "Submitting..." : "Submit" }}
                  </button>
                </div>
              </form>
            </div>

            <p class="fmi">
              <input
                type="radio"
                id="nin"
                name="id-select"
                value="nin"
                v-model="picked"
              />
              NIN Number <br />
            </p>
            <div v-if="picked == 'nin'" class="nin_style">
              <div
                class="notification alert alert-success"
                v-if="nin_success_flag"
              >
                <span>{{ nin_success_flag }}</span>
              </div>
              <div
                class="notification alert alert-danger"
                v-if="nin_errors.length"
              >
                <span v-for="nin_error in nin_errors" v-bind:key="nin_error">{{
                  nin_error
                }}</span>
              </div>

              <form @submit.prevent="submitNin">
                <div class="form-group">
                  <label>NIN number</label>
                  <input
                    type="text"
                    name="nin"
                    id="nin"
                    v-model="nin"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    id="nin"
                    class="btn btn-primary"
                    :disabled="isLoading2"
                  >
                    {{ isLoading2 ? "Submitting..." : "Submit" }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Profile",
  props: ['user'],
  data() {
    return {
      picked: "",
      bvn: "",
      bvn_name: "",
      bvn_errors: [],
      bvn_success_flag: "",
      nin: "",
      nin_errors: [],
      nin_success_flag: "",
      isLoading: false,
      isLoading2: false,
      pageLoading: false,
    };
  },

  methods: {
    submitBvn() {
      this.bvn_errors = [];
      this.bvn_success_flag = "";
      const new_bvn_dob = "3-Jun-1995";
      if (this.bvn == "") {
        this.bvn_errors.push("BVN Number is required");
        this.notify.error("BVN Number is required");
      } else if (this.bvn.length < 11 || this.bvn.length > 11) {
        this.bvn_errors.push("BVN Number must be exactly 11 digits");
        this.notify.error("BVN Number must be exactly 11 digits");
      } else if (this.bvn_name == "") {
        this.bvn_errors.push("Name is required");
        this.notify.error("Name is required");
      } else {
        this.isLoading = true;
        const formData = {
          bvn: this.bvn,
          name: this.bvn_name,
          dob: new_bvn_dob,
          phone: this.user.phone_no,
        };

        this.$axios
          .post("user/update-bvn/", formData)
          .then((response) => {
            // console.log(response.data.data.message);
            // this.close();
            //  this.users.push(this.user);
            this.bvn_success_flag = response.data.message;
            this.notify.success(response.data.message);
            this.isLoading = false;
            // location.href="/dashboard/fund-wallet"
            // this.$router.push({ name: "fundWallet" });
            // window.location.reload();
          })
          .catch((error) => {
            // console.log(error);
            this.bvn_errors = error.response.data.message;
            this.notify.error(error.response.data.message);
            this.isLoading = false;
          });

        // console.log(formData);
      }
    },
    submitNin() {
      this.nin_errors = [];
      // console.log(this.nin);
      if (this.nin == "") {
        this.nin_errors.push("NIN Number is required");
        this.notify.error("NIN Number is required");
      } else if (this.nin.length < 11 || this.nin.length > 11) {
        this.nin_errors.push("NIN Number must be exactly 11 digits");
        this.notify.error("NIN Number must be exactly 11 digits");
      } else {
        this.isLoading = true;
        const formData = {
          nin: this.nin,
        };

        this.$axios
          .post("user/update-nin/", formData)
          .then((response) => {
            // console.log(response);
            // this.close();
            //  this.users.push(this.user);
            this.nin_success_flag = response.data.message;
            this.notify.success(response.data.message);
            this.isLoading = false;
            // location.href="/dashboard/fund-wallet"

            // this.$router.push({ name: "fundWallet" });
            // window.location.reload();
          })
          .catch((error) => {
            // console.log(error);
            this.nin_errors = error.response.data.message;
            this.notify.error(error.response.data.message);
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.m {
  margin-bottom: 50px;
}
label {
  font-weight: bold;
}
a:link {
  text-decoration: none;
}
.btn-primary {
  background-color: #0a8aaf;
  border-color: #0a8aaf;
}
.bvn_style,
.nin_style {
  padding: 0 20px 20px;
}
.fmi {
  margin-bottom: 20px;
}
</style>