import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

class Notify {
  success(text) {
    toast(text, { theme: "colored", type: "success", autoClose: 3000 });
  }
  pending(text) {
    toast(text, { theme: "colored", type: "warning", autoClose: 3000 });
  }
  info(text) {
    toast(text, {
      theme: "colored",
      type: "info",
      position: "top-center",
      autoClose: 5000,
      dangerouslyHTMLString: true,
    });
  }
  error(text) {
    toast(text, { theme: "colored", type: "error", autoClose: 3000 });
  }
}

export default new Notify();